import { withQueryParams } from "utils"

export const getVideoLink = (contentLink: string): string => {
  if (contentLink.includes("vimeo")) {
    return getVimeoLink(contentLink)
  }
  if (contentLink.includes("youtube") || contentLink.includes("youtu.be")) {
    return getYoutubeLink(contentLink)
  }
  if (contentLink.includes("wistia")) {
    return getWistiaLink(contentLink)
  }
  if (contentLink.includes("microsoftstream")) {
    return getMicrosoftStreamLink(contentLink)
  }
  if (contentLink.includes("ted")) {
    return getTedLink(contentLink)
  }
  if (contentLink.includes("dreambroker")) {
    return getDreambrokerLink(contentLink)
  }

  console.warn(`Unknown content link for video ${contentLink}`)
  return contentLink
}

const getWistiaLink = (videoLink: string) => {
  let link = videoLink.replace("https://fast.wistia.com/embed/medias/", "")
  link = link.replace("/swatch", "")
  return link
}

const getVimeoLink = (videoLink: string) => {
  // Unlisted Vimeo videos have privacy hash added at the end of their URLs:
  // - public   - https://vimeo.com/:videoId
  // - unlisted - https://vimeo.com/:videoId/:videoHash
  // videoId is a decimal number, and videoHash is a hexadecimal number.
  // We use RegEx to extract those.

  const vimeoRegex = /^.+vimeo\.com\/(\d+)\/?([\da-f]+)?.*$/
  const match = videoLink.match(vimeoRegex)

  // This should never be the case unless user's link is blatantly wrong
  if (!match) return ""

  const [_fullUrl, videoId, videoHash] = match
  const link = withQueryParams(`https://player.vimeo.com/video/${videoId}`, {
    h: videoHash,
    autoplay: 1,
    loop: 0,
  })

  return link
}

const getMicrosoftStreamLink = (videoLink: string) => {
  // gets the string from the url from: video/code-from-video to build the url
  const videoCode = videoLink.slice(videoLink.indexOf("video"))
  return (
    "https://web.microsoftstream.com/embed/" +
    videoCode +
    "?autoplay=false&amp;showinfo=false"
  )
}

const getYoutubeLink = (videoLink: string) => {
  const regExp =
    /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=)([^#&?]*).*/
  const match = videoLink.match(regExp)
  const videoId = match && match[1]?.length === 11 ? match[1] : false

  const link = withQueryParams(`https://www.youtube.com/embed/${videoId}`, {
    autoplay: 1,
  })
  return link
}

const getTedLink = (videoLink: string) => {
  if (videoLink.includes("embed")) {
    return videoLink
  }

  const videoIndex = videoLink.split("/", 3).join("/").length
  const link = videoLink.slice(videoIndex)
  return "https://embed.ted.com" + link + "?autoplay=1"
}

const getDreambrokerLink = (videoLink: string) => {
  // example link
  // https://dreambroker.com/channel/ezcsaxds/hyf0r7b3

  if (!videoLink.includes("iframe")) {
    const videoIndex = videoLink.split("/", 5).join("/").length
    const linkBegin = videoLink.slice(0, videoIndex) // https://dreambroker.com/channel/ezcsaxds
    const videoId = videoLink.slice(videoIndex) // /hyf0r7b3

    return linkBegin + "/iframe" + videoId
  }

  return videoLink
}
