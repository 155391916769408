import { v3 } from "backoffice-api"
import { leaderboard } from "leaderboard-api"
import { useTranslation } from "react-i18next"
import { QueryBoundary } from "utility-components"
import { custom } from "../../../bonzai/bonzai"
import { useFormatUser } from "../../../bonzai/useFormatUser"
import { getMaxScore } from "../../../dataUtilities/getMaxScore"
import { getProductLink } from "../../../dataUtilities/productDataUtilities"
import { usePickText } from "../../../i18n/usePickText"
import { LeaderboardEntry } from "../../LeaderboardEntry"
import { LeaderboardWidget } from "./LeaderboardWidget"

export const LeaderboardWidgetLoader = () => (
  <QueryBoundary fallback={<LeaderboardWidget.Skeleton />}>
    <Load />
  </QueryBoundary>
)

const Load = () => {
  const { t, i18n } = useTranslation()

  const { product, highScores } = useData()

  const formatUser = useFormatUser()
  const pickText = usePickText()

  if (!product || !highScores) return null

  const maxScore = getMaxScore(highScores.entries)
  const productType = product.product_type.identifier
  const showMyEntry = highScores.my_entry.value > 0

  const items = highScores.entries.map((entry, index) => (
    <LeaderboardEntry
      key={index}
      image={entry.user.image}
      isFirst={index === 0}
      maxScore={maxScore}
      name={formatUser(entry.user)}
      rank={entry.rank}
      score={entry.value}
    />
  ))

  return (
    <LeaderboardWidget
      link={{ to: getProductLink(product.id, productType) }}
      ariaLabel={pickText(product.title)}
    >
      <LeaderboardWidget.Header
        title={pickText(product.title)}
        caption={t("leaderboard.PEOPLE_ARE_PLAYING")}
      />
      <LeaderboardWidget.Entries>
        {items}
        {showMyEntry && (
          <LeaderboardEntry
            image={highScores.my_entry.user.image}
            isFirst={false}
            maxScore={maxScore}
            name={formatUser(highScores.my_entry.user)}
            rank={highScores.my_entry.rank}
            score={highScores.my_entry.value}
          />
        )}
      </LeaderboardWidget.Entries>
    </LeaderboardWidget>
  )
}

const useData = () => {
  const product_id = leaderboard.getMostPlayedProducts.useSuspenseQuery(
    [{ length: 1 }],
    { select: (res) => res.products[0]?.product_id }
  )

  const product = v3.getProduct.useQuery([product_id!], {
    enabled: product_id !== undefined,
    select: (res) => res.data,
  })

  const scope = custom.getLeaderboardScopes.useQuery([], {
    enabled: product_id !== undefined,
    select: (res) => res[0]?.value,
  })

  const highScores = leaderboard.getHighScores.useQuery(
    [{ product_id: product_id!, scope: scope!, length: 4 }],
    { enabled: product_id !== undefined && scope !== undefined }
  )

  return { product, highScores }
}
