import { initGraphQLTada } from "gql.tada"
import type { introspection } from "./generated/graphql-env"
export type * from "./generated/graphql.types"
export { graphqlRequest } from "./graphqlRequest"

export const graphql = initGraphQLTada<{
  introspection: introspection
  scalars: {
    ISO8601DateTime: string
    ID: string
  }
}>()
