import { ReactComponent as MagicStar } from "assets/icons/starMagic.svg"
import journeyUnlockedSunrise from "assets/illustrations/journeyUnlockedSunrise.svg"
import { useHasValueChanged, useOnEscape } from "hooks"
import { useDisableDocumentScroll } from "hooks/src/useDisableDocumentScroll"
import { times } from "lodash-es"
import { Button, Modal } from "materia"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { backgroundImageStyle } from "ui-tools"
import { FiestaImage } from "utility-components"
import { useConfettiBannerStore } from "../../../components/ConfettiBanner/store"
import type { Data } from "../Journey/gql"
import s from "./styles.module.scss"

export const JourneyUnlockedModal = ({
  data,
  initialShowModal,
}: {
  data: Data
  initialShowModal?: boolean
}) => {
  const { isBlocked } = useConfettiBannerStore()
  const [isOpen, setIsOpen] = useState(true)

  const { unlocked } = data

  const hasUnlockedJourneys = unlocked.nodes.length > 0
  const hasUnlockedJourneysChanged = useHasValueChanged(hasUnlockedJourneys)

  const showModal =
    initialShowModal ??
    (isOpen && !isBlocked && hasUnlockedJourneys && hasUnlockedJourneysChanged)

  if (!showModal) return

  return <Content data={data} setIsOpen={setIsOpen} />
}

const Content = ({
  data,
  setIsOpen,
}: {
  data: Data
  setIsOpen: (value: boolean) => void
}) => {
  const { t } = useTranslation()

  const onClose = () => setIsOpen(false)

  useOnEscape(onClose)
  useDisableDocumentScroll()

  const { unlocked } = data

  const journeyImage = unlocked.nodes[0]?.backgroundImageUrl ?? ""
  const journeyTitle = unlocked.nodes[0]?.title ?? ""

  return (
    <Modal
      onClose={onClose}
      overflowAuto={false}
      ariaLabel="Unlocked new journey"
    >
      <div className={s.journeyUnlockedModal}>
        <MagicStars />
        <div
          className={s.journeyUnlockedModal__cover}
          style={backgroundImageStyle(journeyUnlockedSunrise)}
        >
          <FiestaImage
            sizes="90px"
            className={s.journeyUnlockedModal__coverJourneyThumbnail}
            src={journeyImage}
            alt=""
          />
        </div>
        <div className={s.journeyUnlockedModal__content}>
          <h3 className={s.journeyUnlockedModal__contentModalTitle}>
            {t("journey.NEW_JOURNEY_UNLOCKED")}
          </h3>
          <p className={s.journeyUnlockedModal__contentJourneyTitle}>
            {journeyTitle}
          </p>
          <Button onClick={onClose} borderRadius={"32"}>
            {t("journey.NEW_JOURNEY_UNLOCKED_CONTINUE")}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

const MagicStars = () => {
  const NUMBER_OF_STARS = 10 // Manually adjusted in CSS file
  return times(NUMBER_OF_STARS, (index) => (
    <MagicStar className={s.journeyUnlockedModal__star} key={index} />
  ))
}
