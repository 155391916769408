import { Link } from "@tanstack/react-location"
import { ReactComponent as ExploreFilledIcon } from "assets/icons/exploreFilledIcon.svg"
import { ReactComponent as ExploreIcon } from "assets/icons/exploreIcon.svg"
import { ReactComponent as ForYouFilledIcon } from "assets/icons/forYouFilledIcon.svg"
import { ReactComponent as ForYouIcon } from "assets/icons/forYouIcon.svg"
import { ReactComponent as JourneyFilledIcon } from "assets/icons/journeyFilledIcon.svg"
import { ReactComponent as JourneyIcon } from "assets/icons/journeyIcon.svg"
import { ReactComponent as LeaderboardIcon } from "assets/icons/leaderboardIcon.svg"
import { ReactComponent as LeaderboardFilledIcon } from "assets/icons/leaderboardsFilledIcon.svg"
import { ReactComponent as NewsFilledIcon } from "assets/icons/newsFilledIcon.svg"
import { ReactComponent as NewsIcon } from "assets/icons/newsIcon.svg"
import { sortBy } from "lodash-es"
import { type ComponentProps } from "react"
import { useTranslation } from "react-i18next"
import type { LandingPage } from "../../../graphql"
import type { Data } from "./gql"
import s from "./styles.module.scss"

type LinkProps = ComponentProps<typeof NavigationItem>
type LinkWithName = LinkProps & { name: LandingPage }

export const Navigation = ({ data }: { data: Data }) => {
  const { t } = useTranslation()
  const { portalFeatureFlags, portalLandingPage } = data

  const flags = portalFeatureFlags

  if (flags.includes("disable_top_menu")) return null

  const linksWithName: LinkWithName[] = [
    {
      name: "for_you",
      href: "/for-you",
      text: t("navigation.FOR_YOU"),
      Icon: ForYouIcon,
      ActiveIcon: ForYouFilledIcon,
    },
    {
      name: "journey",
      href: "/journey",
      text: t("navigation.JOURNEY"),
      Icon: JourneyIcon,
      ActiveIcon: JourneyFilledIcon,
    },
    {
      name: "explore",
      href: "/explore",
      text: t("navigation.EXPLORE"),
      Icon: ExploreIcon,
      ActiveIcon: ExploreFilledIcon,
    },
    {
      name: "leaderboard",
      href: "/leaderboards",
      text: t("navigation.LEADERBOARDS"),
      Icon: LeaderboardIcon,
      ActiveIcon: LeaderboardFilledIcon,
    },
    {
      name: "news",
      href: "/news",
      text: t("navigation.NEWS"),
      Icon: NewsIcon,
      ActiveIcon: NewsFilledIcon,
    },
  ]

  const enabledLinks = linksWithName.filter(({ name }) => {
    if (name === "journey") return flags.includes("enable_learning_journey")
    if (name === "leaderboard") return !flags.includes("disable_leaderboard")
    if (name === "news") return flags.includes("enable_portal_news")
    return true
  })

  const sortedByLandingPage = sortBy(enabledLinks, (page) =>
    page.name === portalLandingPage ? 0 : 1
  )

  const links = sortedByLandingPage.map(({ name: page, ...props }) => (
    <NavigationItem key={page} {...props} />
  ))

  return (
    <nav className={s.navigation}>
      <ul className={s.navigationLinks}>{links}</ul>
    </nav>
  )
}

const NavigationItem = ({
  href,
  text,
  Icon,
  ActiveIcon,
}: {
  href: string
  Icon: React.ElementType
  ActiveIcon: React.ElementType
  text: string
}) => (
  <li>
    <Link
      to={href}
      search={{}}
      className={s.link}
      getActiveProps={getActiveProps}
    >
      {({ isActive }) => (
        <>
          {isActive ? (
            <ActiveIcon className={s.link__icon} alt="" aria-label={text} />
          ) : (
            <Icon className={s.link__icon} alt="" aria-label={text} />
          )}
          <div className={s.link__text}>{text}</div>
        </>
      )}
    </Link>
  </li>
)

const getActiveProps = () => ({
  className: s["link--active"],
  "aria-current": "page",
})
