import { v3 } from "backoffice-api"
import { useModal, useQueryParam, useRouteParamNumber } from "hooks"
import { Loader } from "materia"
import { QueryBoundary } from "utility-components"
import { getPreferredUploadV3 } from "../../dataUtilities/productDataUtilities"
import { usePickText } from "../../i18n/usePickText"
import { usePageTitle } from "../../tools/usePageTitle"
import { useBlockConfettiBanner } from "../ConfettiBanner/useBlockConfettiBanner"
import { ViewerModal } from "./ViewerModal"

type PdfModalLoaderProps = {
  closeRoute: string
}
export const PdfModalLoader = ({ closeRoute }: PdfModalLoaderProps) => {
  const productId = useRouteParamNumber("productId")

  useBlockConfettiBanner()

  const { onClose } = useModal(closeRoute)

  return (
    <ViewerModal onClose={onClose}>
      <QueryBoundary fallback={<Loader />}>
        <Load productId={productId} onClose={onClose} />
      </QueryBoundary>
    </ViewerModal>
  )
}

type PdfViewerLoaderProps = {
  productId: number
  onClose: () => void
}
const Load = ({ productId, onClose }: PdfViewerLoaderProps) => {
  const pickText = usePickText()

  const product = v3.getProduct.useSuspenseQuery([productId], {
    select: (res) => res.data,
  })

  const [content_locale] = useQueryParam("content_locale")

  const pdfSrc = getPreferredUploadV3(product, content_locale)
  const title = pickText(product.title)

  usePageTitle(title)

  return (
    <ViewerModal.FullScreen headerText={title} src={pdfSrc} onClose={onClose} />
  )
}
