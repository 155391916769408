import { v3 } from "backoffice-api"
import { useQueryAll } from "bonzai"
import { formatNumber } from "format"
import { useQueryParam } from "hooks"
import { leaderboard } from "leaderboard-api"
import { times } from "lodash-es"
import { useTranslation } from "react-i18next"
import { QueryBoundary, WhenVisible } from "utility-components"
import { custom } from "../../../bonzai/bonzai"
import { getNextPageParamV1 } from "../../../bonzai/getNextPageParamV1"
import { useFormatUser } from "../../../bonzai/useFormatUser"
import { getProductLink } from "../../../dataUtilities/productDataUtilities"
import { usePickText } from "../../../i18n/usePickText"
import { ConfigError } from "../../../tools/ConfigError"
import { LeaderboardViewCompact } from "./LeaderboardViewCompact"

type LeaderboardScope = custom["getLeaderboardScopes"][number]

const PRODUCTS_PER_PAGE = 6

export const LeaderboardViewCompactLoader = () => (
  <LeaderboardViewCompact>
    <QueryBoundary fallback={<LeaderboardSkeletons />}>
      <Load />
    </QueryBoundary>
  </LeaderboardViewCompact>
)

const Load = () => {
  const { scopes, scope, setScope } = useLeaderboardScopes()

  return (
    <>
      <Scopes scopes={scopes} value={scope} onChange={setScope} />
      <QueryBoundary fallback={<LeaderboardSkeletons />}>
        <LeaderboardPages scope={scope} />
      </QueryBoundary>
    </>
  )
}

type ScopesProps = {
  value: string
  onChange: (value: string) => void
  scopes: LeaderboardScope[]
}
const Scopes = ({ scopes, value, onChange }: ScopesProps) => {
  const scopeElements = scopes.map((scope) => (
    <LeaderboardViewCompact.Scope value={scope.value} key={scope.value}>
      {scope.name}
    </LeaderboardViewCompact.Scope>
  ))

  return (
    <LeaderboardViewCompact.ScopeSelect value={value} onChange={onChange}>
      {scopeElements}
    </LeaderboardViewCompact.ScopeSelect>
  )
}

type LeaderboardPagesProps = {
  scope: string
}
const LeaderboardPages = ({ scope }: LeaderboardPagesProps) => {
  const { data, fetchNextPage, isFetchingNextPage } = useLeaderboardsData()

  const productIds = data.pages.flatMap((page) => page.data)
  const leaderboards = productIds.map((productId) => (
    <QueryBoundary fallback={<LeaderboardViewCompact.Skeleton />}>
      <Leaderboard
        key={`product-${productId}`}
        productId={productId}
        scope={scope}
      />
    </QueryBoundary>
  ))

  return (
    <>
      {leaderboards}
      {isFetchingNextPage && <LeaderboardSkeletons />}

      <WhenVisible
        key={`${scope}-${productIds.length}`}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    </>
  )
}

type LeaderboardProps = {
  productId: number
  scope: string
}
const Leaderboard = ({ productId, scope }: LeaderboardProps) => {
  const { t, i18n } = useTranslation()
  const [highScores, product] = useLeaderboardData(productId, scope)
  const formatUser = useFormatUser()
  const pickText = usePickText()

  if (highScores.entries.length === 0) return null

  const showMyEntry = highScores.my_entry.value > 0

  const podiumEntries = highScores.entries
    .slice(0, 3)
    .map((entry, index) => (
      <LeaderboardViewCompact.EntryPodium
        key={`podium-${index}`}
        rank={entry.rank}
        avatar={entry.user.image}
        name={formatUser(entry.user)}
        score={formatNumber(entry.value, i18n.language)}
        isFirst={index === 0}
      />
    ))

  const entries = highScores.entries
    .slice(3)
    .map((entry, index) => (
      <LeaderboardViewCompact.Entry
        key={`entry-${index}`}
        rank={entry.rank}
        avatar={entry.user.image}
        name={formatUser(entry.user)}
        score={formatNumber(entry.value, i18n.language)}
        isMe={false}
      />
    ))

  return (
    <LeaderboardViewCompact.Leaderboard>
      <LeaderboardViewCompact.Header
        link={{
          to: getProductLink(product.id, product.product_type.identifier),
        }}
        title={pickText(product.title)}
        linkText={t("leaderboard.SEE_ALL")}
      />
      <LeaderboardViewCompact.Podium>
        {podiumEntries}
      </LeaderboardViewCompact.Podium>
      {entries}
      {showMyEntry && (
        <LeaderboardViewCompact.Entry
          rank={highScores.my_entry.rank}
          avatar={highScores.my_entry.user.image}
          name={formatUser(highScores.my_entry.user)}
          score={formatNumber(highScores.my_entry.value, i18n.language)}
          isMe={true}
        />
      )}
    </LeaderboardViewCompact.Leaderboard>
  )
}

const LeaderboardSkeletons = () => (
  <>
    {times(PRODUCTS_PER_PAGE, () => (
      <LeaderboardViewCompact.Skeleton />
    ))}
  </>
)

const useLeaderboardScopes = () => {
  const scopes = custom.getLeaderboardScopes.useSuspenseQuery([])
  const firstScope = scopes[0]

  if (firstScope === undefined) {
    throw new ConfigError("No leaderboard scopes")
  }

  const [scope = firstScope.value, setScope] = useQueryParam("scope")

  return { scopes, scope, setScope }
}

const useLeaderboardsData = () => {
  return custom.getPlayableProductIds.useSuspenseInfiniteQuery(
    [{ per_page: PRODUCTS_PER_PAGE }],
    {
      initialPageParam: { page: 1 },
      getNextPageParam: getNextPageParamV1,
    }
  )
}

const useLeaderboardData = (product_id: number, scope: string) => {
  return useQueryAll(() => [
    leaderboard.getHighScores.useSuspenseQuery([
      { product_id, scope, length: 6 },
    ]),

    v3.getProduct.useSuspenseQuery([product_id], {
      select: (res) => res.data,
    }),
  ])
}
