import { ReactComponent as PlayButton } from "assets/icons/playButton.svg"
import diplomaMedalLocked from "assets/illustrations/diplomaMedalLocked.svg"
import diplomaRibbonLocked from "assets/illustrations/diplomaRibbonLocked.svg"
import { useTranslation } from "react-i18next"
import { FiestaLink, type FiestaLinkProps } from "utility-components"
import type { DiplomaPreviewProps } from "../types"

import s from "./styles.module.scss"

export const DiplomaPreviewLocked = (props: DiplomaPreviewProps) => {
  const { t } = useTranslation()

  return (
    <div className={s.diploma}>
      <div className={s.top}>
        <img
          className={s.medal}
          src={diplomaMedalLocked}
          alt="Locked diploma medal"
        />

        <div className={s.ribbon}>
          <div className={s.ribbon__text}>{props.title}</div>
          <img className={s.ribbon__img} src={diplomaRibbonLocked} alt="" />
        </div>
      </div>

      <div className={s.bottom}>
        <div className={s.bottom__text}>{t("diploma.COMPLETE_TO_VERIFY")}</div>
        <div className={s.bottom__productTitle}>{props.productTitle}</div>
        <PlayLink to={props.productLink}>{t("diploma.GO_TO_PRODUCT")}</PlayLink>
      </div>
    </div>
  )
}

// TODO [WEB-16119] Add link to product page
const PlayLink = ({ children, ...props }: FiestaLinkProps) => (
  <FiestaLink className={s.playLink} {...props}>
    <PlayButton className={s.playLink__icon} />
    {children}
  </FiestaLink>
)
