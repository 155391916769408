import { v3 } from "backoffice-api"
import { useAsyncData, useModal, useRouteParamNumber } from "hooks"
import { Loader } from "materia"
import { ErrorPopup } from "utility-components"
import { custom } from "../../bonzai/bonzai"
import { usePickText } from "../../i18n/usePickText"
import { useIframeMessages } from "../../tools/useIframeMessages"
import { usePageTitle } from "../../tools/usePageTitle"
import { useBlockConfettiBanner } from "../ConfettiBanner/useBlockConfettiBanner"
import { ViewerModal } from "./ViewerModal"

type Product = v3["getProduct"]["data"]
type ProductType = Product["product_type"]["identifier"]

export const WebGLModalLoader = ({ closeRoute }: { closeRoute: string }) => {
  const productId = useRouteParamNumber("productId")

  useBlockConfettiBanner()

  const { onClose } = useModal(closeRoute)

  const product = v3.getProduct.useQuery([productId], {
    select: (res) => res.data,
  })

  return (
    <ViewerModal onClose={onClose}>
      {product ? <Load product={product} onClose={onClose} /> : <Loader />}
    </ViewerModal>
  )
}

const Load = ({
  product,
  onClose,
}: {
  product: Product
  onClose: () => void
}) => {
  const pickText = usePickText()

  const title = pickText(product.title)
  usePageTitle(title)

  useIframeMessages({
    onClose,
    targetUrl: product.content_link,
  })

  // Doesn't use React Query here to avoid caching and re-generating the link when React Query refreshes data.
  const {
    data: webGLLink,
    isPending,
    error,
  } = useAsyncData(() => custom.getWebGLLink(product), [product.id])

  if (isPending) return <Loader />
  if (error) return <ErrorPopup error={error} />

  if (webGLLink === undefined) {
    throw new Error(`No webGLLink for product ${product.id}`)
  }

  return (
    <ViewerModal.WebGL
      src={webGLLink}
      orientation={getProductOrientation(product.product_type.identifier)}
      onClose={onClose}
    />
  )
}

const getProductOrientation = (type: ProductType) => {
  if (type === "skill") return "portrait"

  return "landscape"
}
