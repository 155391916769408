import { auth } from "auth"
import {
  API_TOKEN,
  APP_ENV,
  AUTH_URL,
  BACKOFFICE_API_URL,
  LOGIN_URL,
} from "../env"

type Config = {
  storybookMode?: boolean
}
export const initializeAuth = ({ storybookMode }: Config = {}) => {
  auth.initializeAuth({
    authUrl: AUTH_URL,
    loginUrl: LOGIN_URL,
    backofficeUrl: BACKOFFICE_API_URL,
    apiToken: API_TOKEN,
    subdomain: storybookMode ? undefined : auth.getSubdomainFromPath(),
    redirectStrategy: storybookMode ? "href" : undefined,
    environment: APP_ENV,
    locale: undefined,
  })
}
