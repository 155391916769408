import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"
import {
  mockProductModalMainData,
  productModalMainFragment,
} from "./ProductModalMain/gql"

export type Data = ResultOf<typeof query>

export const query = graphql(
  `
    query ProductModal($productId: ID!) {
      currentCompany {
        portalConfigurations
      }
      product(id: $productId) {
        ...ProductModalMain
        isLocked
      }
    }
  `,
  [productModalMainFragment]
)

export const mockProductModalData = mockData<Data>({
  currentCompany: {
    portalConfigurations: [],
  },
  product: {
    ...mockProductModalMainData(),
    isLocked: false,
  },
})

registerMockData(query, mockProductModalData())
