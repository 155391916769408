import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>
export type Product = Data["product"]

export const query = graphql(
  `
    query VideoProduct($productId: ID!) {
      product(id: $productId) {
        id
        title
        contentLink
        localeContentLinks {
          nodes {
            link
            locale
          }
        }
        progress {
          isCertified
        }
      }
      currentCompany {
        portalFeatureFlags
      }
    }
  `
)

export const mockVideoProductData = mockData<Data>({
  product: {
    id: "1",
    title: "Product title",
    contentLink: "https://http.cat/404",
    localeContentLinks: {
      nodes: [{ link: "https://http.cat/404", locale: "en-US" }],
    },
    progress: {
      isCertified: true,
    },
  },
  currentCompany: {
    portalFeatureFlags: ["enable_no_skip_videos"],
  },
})

registerMockData(query, mockVideoProductData())
