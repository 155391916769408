import { withQueryParams } from "utils"
import { accessTokenToTransferKey } from "./api/transferKey"
import { getActiveSession } from "./getActiveSession"

export const getLinkWithActiveSession = async ({ url }: { url: string }) => {
  const { subdomain, accessToken, companyNameKey } = await getActiveSession()

  return withQueryParams(url, {
    subdomain,
    transfer_key: await accessTokenToTransferKey(accessToken),
    company_name_key: companyNameKey,
  })
}
