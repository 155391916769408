import closeIconWhite from "assets/icons/closeIconWhite.svg"
import { useGraphqlQuery } from "graphql-mock"
import { useModal, useQueryParam, useRouteParamNumber } from "hooks"
import { Loader } from "materia"
import { useState } from "react"
import { ErrorPopup } from "utility-components"
import { getVideoLink } from "../../../dataUtilities/getVideoLink"
import { createPlaythrough } from "../../../graphql/createPlaythrough"
import { usePageTitle } from "../../../tools/usePageTitle"
import { ViewerModal } from "../ViewerModal"
import { query, type Data, type Product } from "./gql"
import { VideoPlayer } from "./VideoPlayer"

import s from "./styles.module.scss"

export const VideoModalLoader = ({ closeRoute }: { closeRoute: string }) => {
  const productId = useRouteParamNumber("productId")
  const { onClose } = useModal(closeRoute)

  const { data, isPending, error } = useGraphqlQuery(query, {
    productId: String(productId),
  })

  if (isPending)
    return (
      <ViewerModal onClose={onClose}>
        <Loader />
      </ViewerModal>
    )
  if (error) return <ErrorPopup error={error} />

  return <VideoModal data={data} closeRoute={closeRoute} />
}

const VideoModal = ({
  data,
  closeRoute,
}: {
  data: Data
  closeRoute: string
}) => {
  const { product, currentCompany } = data

  usePageTitle(product.title)

  const [content_locale] = useQueryParam("content_locale")
  const [shouldCreatePlaythrough, setShouldCreatePlaythrough] = useState(false)

  const isSeekAheadDisabled =
    currentCompany.portalFeatureFlags.includes("enable_no_skip_videos") &&
    !product.progress.isCertified
  const localizedVideoLink = getLocalizedVideoLink(product, content_locale)
  const videoLink = getVideoLink(localizedVideoLink)

  const createVideoPlaythrough = () => {
    if (shouldCreatePlaythrough || !isSeekAheadDisabled) {
      createPlaythrough(product.id)
    }
  }

  const { onClose } = useModal(closeRoute, createVideoPlaythrough)

  return (
    <ViewerModal onClose={onClose}>
      <div className={s.fullScreen}>
        <div className={s.fullScreen__header}>
          <div>{product.title}</div>
          <CloseButton onClick={onClose} />
        </div>
        <div className={s.fullScreen__content}>
          <VideoPlayer
            src={videoLink}
            isSeekAheadDisabled={isSeekAheadDisabled}
            setShouldCreatePlaythrough={setShouldCreatePlaythrough}
          />
        </div>
      </div>
    </ViewerModal>
  )
}

type CloseButtonProps = {
  onClick: () => void
}
const CloseButton = ({ onClick }: CloseButtonProps) => (
  <button className={s.closeButton} onClick={onClick}>
    <img className={s.closeButton__icon} src={closeIconWhite} alt="Close" />
  </button>
)

const getLocalizedVideoLink = (
  product: Product,
  locale: string | undefined
) => {
  const links = product.localeContentLinks.nodes ?? []
  const preferredLink = links.find((link) => link.locale === locale)

  const contentLink =
    preferredLink?.link ??
    // If link in preferred locale does not exist, we fallback to any available locale.
    // User will be allowed to select a different link locale from the list.
    links[0]?.link ??
    // Older products won't have localized content links at all,
    // so we show the old content link instead.
    product.contentLink

  return contentLink
}
