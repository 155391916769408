import productImage from "assets/demo/productImage.svg"
import { mockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"

export type Data = ResultOf<typeof productCardFragment>
export type Course = NonNullable<Data["course"]>
export type Progress = Data["progress"]
export type DeadlineOccurrence = NonNullable<Data["deadlineOccurrence"]>

export const productCardFragment = graphql(`
  fragment ProductCard on Product @_unmask {
    id
    image
    title
    productType
    isMandatory
    isLocked
    progress {
      isCertified
      starsCount
      starsMax
      highScore
      inProgress
    }
    activityProgress {
      complete
      progressCount
      progressOf
    }
    deadlineOccurrence {
      status
    }
    course {
      isAttending
      isOnWaitingList
      isFull
      isCancelled
      startDatetime
    }
  }
`)

export const mockProductCardData = mockData<Data>({
  id: "1",
  image: productImage,
  productType: "pdf",
  title: "Product Title",
  isMandatory: true,
  isLocked: false,
  progress: {
    isCertified: true,
    highScore: 1334,
    inProgress: false,
    starsCount: 3,
    starsMax: 1,
  },
  activityProgress: {
    complete: true,
    progressCount: 5,
    progressOf: 10,
  },
  deadlineOccurrence: {
    status: "certified",
  },
  course: {
    isAttending: false,
    isCancelled: false,
    isFull: false,
    isOnWaitingList: false,
    startDatetime: "2053-11-07T14:15:34.989Z",
  },
})
