import attendeesIcon from "assets/icons/attendees.svg"
import durationIcon from "assets/icons/duration.svg"
import hostIcon from "assets/icons/host.svg"
import roomIcon from "assets/icons/room.svg"
import { v3 } from "backoffice-api"
import { useToggle } from "hooks"
import { sortBy } from "lodash-es"
import { useWindowWidth } from "materia"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { QueryBoundary } from "utility-components"
import { toggleAttending } from "../../../bonzai/toggleAttending"
import { toggleWaitingList } from "../../../bonzai/toggleWaitingList"
import {
  formatEventDate,
  formatEventDay,
  formatEventDayMonth,
  formatEventFullDate,
  formatEventLength,
  formatEventTime,
  getHasEventExpired,
  getIsEventFull,
  getIsEventPastDeadline,
  pickEventDate,
} from "../../../dataUtilities/eventDataUtilities"
import { getIsUrl } from "../../../dataUtilities/getIsUrl"
import { useGetOrdinal } from "../../../dataUtilities/getOrdinal"
import { addToCalendar } from "../../../tools/addToCalendar"
import { IS_APP } from "../../../vuplex/constants"
import { sendVuplexMessage } from "../../../vuplex/sendVuplexMessage"
import { ProductModalActivity } from "../ProductModalActivity/ProductModalActivity"
import { ProductModalEvent } from "./ProductModalEvent"

type EventDate = v3["getProductCourse"]["data"]

type ProductModalEventLoaderProps = {
  productId: number
}
export const ProductModalEventLoader = (
  props: ProductModalEventLoaderProps
) => (
  <QueryBoundary
    fallback={<ProductModalActivity.Skeleton />}
    isSuspense={useWindowWidth().isBigWindow}
  >
    <Load {...props} />
  </QueryBoundary>
)

const Load = ({ productId }: ProductModalEventLoaderProps) => {
  const dates = useProductDates(productId)

  const [activeDateId, setActiveDateId] = useState(
    () => pickEventDate(dates)?.id
  )

  if (dates.length === 0) return <Empty />

  const activeDate = dates.find((date) => date.id === activeDateId) ?? dates[0]

  if (!activeDate) throw new Error("Unable to pick an active date")

  const getHeader = () => {
    if (activeDate.is_attending) {
      return <Attending activeDate={activeDate} productId={productId} />
    }

    if (activeDate.is_on_waiting_list) {
      return <WaitingList activeDate={activeDate} />
    }

    return (
      <NotAttending
        activeDate={activeDate}
        activeDateId={activeDateId}
        dates={dates}
        setActiveDateId={setActiveDateId}
      />
    )
  }

  return (
    <ProductModalEvent>
      <div aria-live="polite">{getHeader()}</div>
      <InfoList activeDate={activeDate} />
      <Buttons activeDate={activeDate} />
    </ProductModalEvent>
  )
}

const Empty = () => {
  const { t } = useTranslation()

  return (
    <ProductModalEvent>
      <ProductModalEvent.Empty header={t("event.NO_DATES_AVAILABLE")} />
    </ProductModalEvent>
  )
}

type AttendingProps = {
  activeDate: EventDate
  productId: number
}
const Attending = ({ activeDate, productId }: AttendingProps) => {
  const { t, i18n } = useTranslation()

  const product = v3.getProduct.useSuspenseQuery([productId], {
    select: (res) => res.data,
  })

  return (
    <>
      <ProductModalEvent.Ticket
        attendingText={t("event.ATTENDING")}
        date={formatEventFullDate(activeDate, i18n.language)}
        time={formatEventTime(activeDate, i18n.language)}
        isWaiting={false}
      />
      <AttendingLinkButtons
        courseMaterial={activeDate.course_material}
        meetingLink={activeDate.meeting_link}
      />
      <ProductModalEvent.AddToCalendar
        text={t("event.ADD_TO_CALENDAR")}
        onClick={() => addToCalendar(product, activeDate)}
      />
    </>
  )
}

type AttendingLinkButtonsProps = {
  courseMaterial: string | null
  meetingLink: string | null
}

const AttendingLinkButtons = ({
  courseMaterial,
  meetingLink,
}: AttendingLinkButtonsProps) => {
  const { t } = useTranslation()
  return (
    <ProductModalEvent.DateButtons>
      <EventLink
        link={courseMaterial}
        color="white"
        text={t("event.COURSE_MATERIAL_LINK")}
      />
      <EventLink
        link={meetingLink}
        color="blue"
        text={t("event.JOIN_VIDEO_MEETING")}
      />
    </ProductModalEvent.DateButtons>
  )
}

type EventLinkProps = {
  color: "white" | "blue"
  text: string
  link: string | null
}
const EventLink = ({ link, text, color }: EventLinkProps) => {
  if (!link) return

  const onClick = () => {
    if (IS_APP) {
      sendVuplexMessage({
        type: "OPEN_LINK",
        payload: { link, openExternalBrowser: true },
      })
    } else {
      window.open(link, "_blank")
    }
  }

  return (
    <ProductModalEvent.DateButton text={text} color={color} onClick={onClick} />
  )
}

type WaitingListProps = {
  activeDate: EventDate
}
const WaitingList = ({ activeDate }: WaitingListProps) => {
  const { waiting_list_position } = activeDate
  const { t, i18n } = useTranslation()
  const getOrdinal = useGetOrdinal()

  return (
    <>
      <ProductModalEvent.Ticket
        attendingText={t("event.ON_WAITING_LIST_FOR")}
        date={formatEventFullDate(activeDate, i18n.language)}
        time={formatEventTime(activeDate, i18n.language)}
        isWaiting={true}
      />
      <ProductModalEvent.DateButtons>
        <EventLink
          text={t("event.COURSE_MATERIAL_LINK")}
          color="white"
          link={activeDate.course_material}
        />
      </ProductModalEvent.DateButtons>
      <ProductModalEvent.WaitingInfo
        text={`${getOrdinal(waiting_list_position)} ${t("event.IN_LINE")}`}
      />
    </>
  )
}

type NotAttendingProps = {
  dates: EventDate[]
  activeDate: EventDate
  activeDateId: string | undefined
  setActiveDateId: (id: string) => void
}
const NotAttending = ({
  dates,
  activeDate,
  activeDateId,
  setActiveDateId,
}: NotAttendingProps) => {
  const { t, i18n } = useTranslation()
  const [showMoreDates, toggleShowMoreDates] = useToggle(false)
  const { isBigWindow } = useWindowWidth()

  const hasExtraDates = dates.length > 3
  const toggleShowMoreButton = isBigWindow && hasExtraDates
  const shouldLimitDates = isBigWindow && hasExtraDates && !showMoreDates
  const displayDates = shouldLimitDates ? dates.slice(0, 3) : dates

  const eventDates = displayDates.map((event) => {
    const { month, time } = formatEventDate(event, i18n.language)

    return (
      <ProductModalEvent.Date
        key={event.id}
        isCancelled={event.cancelled}
        isActive={activeDateId === event.id}
        onClick={() => setActiveDateId(event.id)}
        day={formatEventDay(event)}
        month={month}
        time={event.cancelled ? t("event.CANCELLED") : time}
      />
    )
  })

  const headerText = getIsEventFull(activeDate)
    ? t("event.NO_SPOTS_LEFT")
    : t("event.AVAILABLE_DATES")

  const toggleShowMoreText = showMoreDates
    ? `${t("event.LESS")}`
    : `${t("event.MORE")}`

  return (
    <>
      <ProductModalEvent.Header
        color={getIsEventFull(activeDate) ? "red" : undefined}
        text={headerText}
      />

      <ProductModalEvent.Dates isOverflow>
        {eventDates}
        {toggleShowMoreButton && (
          <ProductModalEvent.ToggleShowMore
            buttonText={toggleShowMoreText}
            onClick={toggleShowMoreDates}
          />
        )}
      </ProductModalEvent.Dates>
    </>
  )
}

type InfoListProps = {
  activeDate: EventDate
}
const InfoList = ({ activeDate }: InfoListProps) => {
  const { t } = useTranslation()

  const {
    room,
    instructor,
    attendees_count,
    is_on_waiting_list,
    attendees_count_max,
    location,
  } = activeDate

  return (
    <ProductModalEvent.InfoList
      isOpaque={is_on_waiting_list}
      address={location}
      isUrl={getIsUrl(location)}
    >
      <ProductModalEvent.Info
        icon={durationIcon}
        label={t("event.DURATION")}
        value={formatEventLength(activeDate, t)}
      />
      <ProductModalEvent.Info
        icon={hostIcon}
        label={t("event.HOST")}
        value={instructor}
      />
      {room && (
        <ProductModalEvent.Info
          icon={roomIcon}
          label={t("event.ROOM")}
          value={room}
        />
      )}
      <ProductModalEvent.Info
        icon={attendeesIcon}
        label={t("event.ATTENDEES")}
        value={`${attendees_count}/${attendees_count_max}`}
        color={getIsEventFull(activeDate) ? "red" : undefined}
      />
    </ProductModalEvent.InfoList>
  )
}

type ButtonsProps = {
  activeDate: EventDate
}
const Buttons = ({ activeDate }: ButtonsProps) => {
  if (activeDate.cancelled) {
    return
  }

  const { is_attending, is_on_waiting_list, signup_deadline } = activeDate
  const isPastDeadline = getIsEventPastDeadline(signup_deadline)

  const getButton = () => {
    if (is_attending) {
      return <UnregisterButton onClick={() => toggleAttending(activeDate)} />
    }

    if (is_on_waiting_list) {
      return <UnregisterButton onClick={() => toggleWaitingList(activeDate)} />
    }

    if (isPastDeadline) return <DeadlinePassedButton />

    if (getIsEventFull(activeDate)) {
      if (!activeDate.waiting_list_enabled) return null

      return (
        <WaitingListButton
          activeDate={activeDate}
          onClick={() => toggleWaitingList(activeDate)}
        />
      )
    }

    return (
      <AttendButton
        activeDate={activeDate}
        onClick={() => toggleAttending(activeDate)}
      />
    )
  }

  return <ProductModalEvent.Buttons>{getButton()}</ProductModalEvent.Buttons>
}

type WaitingListButtonProps = {
  activeDate: EventDate
  onClick: () => void
}
const WaitingListButton = ({ activeDate, onClick }: WaitingListButtonProps) => {
  const { t, i18n } = useTranslation()
  return (
    <ProductModalEvent.Button variant="waiting-list" onClick={onClick}>
      {t("event.PLACE_ME_ON_WAITING_LIST")}{" "}
      {formatEventDayMonth(activeDate, i18n.language)}
    </ProductModalEvent.Button>
  )
}

type AttendButtonProps = {
  activeDate: EventDate
  onClick: () => void
}
const AttendButton = ({ activeDate, onClick }: AttendButtonProps) => {
  const { t, i18n } = useTranslation()
  return (
    <ProductModalEvent.Button variant="attend" onClick={onClick}>
      {t("event.ATTEND")} {formatEventDayMonth(activeDate, i18n.language)}
    </ProductModalEvent.Button>
  )
}

type UnregisterButtonProps = {
  onClick: () => void
}
const UnregisterButton = ({ onClick }: UnregisterButtonProps) => {
  const { t } = useTranslation()
  return (
    <ProductModalEvent.Button variant="unregister" onClick={onClick}>
      {t("event.UNREGISTER")}
    </ProductModalEvent.Button>
  )
}

const DeadlinePassedButton = () => {
  const { t } = useTranslation()
  return (
    <ProductModalEvent.Button variant="disabled" onClick={() => {}}>
      {t("event.DEADLINE_PASSED")}
    </ProductModalEvent.Button>
  )
}

const useProductDates = (productId: number) => {
  return v3.getProduct.useSuspenseQuery([productId, { include: ["courses"] }], {
    select: (res) => getActiveEvents(res.data.courses ?? []),
  })
}

const getActiveEvents = (courses: EventDate[]) => {
  const activeEvents = courses.filter((date) => !getHasEventExpired(date))
  return sortBy(activeEvents, (event) => event.start_datetime)
}
