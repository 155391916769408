import searchIcon from "assets/icons/searchIcon.svg"
import { useQueryParam, useScrollToTopOnMount, type SetQueryParam } from "hooks"
import { debounce } from "lodash-es"
import { forwardRef, useMemo, type InputHTMLAttributes } from "react"
import { useTranslation } from "react-i18next"
import { FilteredProducts } from "../../components/FilteredProducts"
import { HiddenHeader } from "../../components/HiddenHeader"
import type { ProductTypeIdentifier } from "../../graphql"
import { usePageTitle } from "../../tools/usePageTitle"
import { ExploreFilters } from "./ExploreFilters"
import s from "./styles.module.scss"

export const ExploreView = () => {
  useScrollToTopOnMount()

  const { t } = useTranslation()
  usePageTitle(t("pageTitle.EXPLORE"))

  const [search, setSearch] = useQueryParam("search")
  const [productType, setActiveType] =
    useQueryParam<ProductTypeIdentifier>("product_type")

  return (
    <>
      <HiddenHeader title={t(`navigation.EXPLORE`)} tag="h1" />
    <search className={s.searchContainer}>
      <Search search={search} setSearch={setSearch} />
      <ExploreFilters
        productType={productType}
        setActiveType={setActiveType}
      />
    </search>
      <HiddenHeader title={t(`product.PRODUCTS`)} tag="h2" />
      <FilteredProducts input={{ variant: "explore", search, productType }} />
    </>
  )
}

const Search = ({
  search,
  setSearch,
}: {
  search: string | undefined
  setSearch: SetQueryParam<string>
}) => {
  const { t } = useTranslation()

  const debouncedOnSearch = useMemo(() => debounce(setSearch, 500), [setSearch])

  const onKeyDown = (key: string, value: string) => {
    if (key === "Enter") setSearch(value)
  }

  return (
    <SearchInput
      defaultValue={search}
      placeholder={t("searchbar.PLACEHOLDER")}
      onKeyDown={(e) => onKeyDown(e.key, e.currentTarget.value)}
      onChange={(e) => debouncedOnSearch(e.currentTarget.value)}
    />
  )
}

const SearchInput = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
  const { t } = useTranslation()

  return (
    <div className={s.search}>
      <img className={s.search__icon} src={searchIcon} alt="" />
      <label className={s.search__label}>
        {t(`pages.SEARCH_LABEL`)}
        <input ref={ref} className={s.search__input} {...props} />
      </label>
    </div>
  )
})
