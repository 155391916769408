import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>
export type Product = Data["products"]["nodes"][number]
export type Course = NonNullable<Product["course"]>

export const query = graphql(`
  query EventWidgetMobile {
    currentCompany {
      portalConfigurations
    }
    products(limit: 1, input: { variant: events }) {
      nodes {
        id
        title
        course {
          isAttending
          startDatetime
        }
      }
    }
  }
`)

export const mockEventWidgetMobileData = mockData<Data>({
  currentCompany: {
    portalConfigurations: [],
  },
  products: {
    nodes: [
      {
        id: "1",
        title: "Event",
        course: {
          isAttending: false,
          startDatetime: "2022-01-01T00:00:00Z",
        },
      },
    ],
  },
})

registerMockData(query, mockEventWidgetMobileData())
