import { auth, useLinkWithActiveSession } from "auth"
import { v3 } from "backoffice-api"
import { useToggle } from "hooks"
import { Dropdown, useWindowWidth } from "materia"
import { useEffect, useState, type ComponentProps } from "react"
import { useTranslation } from "react-i18next"
import { ErrorPopup, type FiestaLinkProps } from "utility-components"
import { DEVICE_PLATFORM } from "../../../constants"
import {
  getLauncherLink,
  getStartAppLink,
  getWebGLLink,
} from "../../../customApi"
import { preloadPlayLinksData } from "../../../customApi/getPlayLinks"
import { getLocaleDisplayName } from "../../../dataUtilities/getLocaleDisplayName"
import { getPlayCapabilites } from "../../../dataUtilities/getPlayCapabilites"
import {
  getInstallAppLink,
  getIsPlayableV3,
  getPreferredContentLink,
  getPreferredUploadV3,
} from "../../../dataUtilities/productDataUtilities"
import { createPlaythrough } from "../../../graphql/createPlaythrough"
import { getStaticLauncherLink } from "../../../tools/getStaticLauncherLink"
import { IS_APP } from "../../../vuplex/constants"
import { isHandledByVuplex } from "../../../vuplex/isHandledByVuplex"
import { sendOpenProductMessage } from "../../../vuplex/sendOpenProductMessage"
import { sendVuplexMessage } from "../../../vuplex/sendVuplexMessage"
import { LauncherOverlay } from "../LauncherOverlay/LauncherOverlay"
import { ProductModalDownload } from "../ProductModalDownload/index"
import { ProductModalHelpLoader } from "../ProductModalHelp/ProductModalHelpLoader"
import { ProductModalMain } from "../ProductModalMain"

type Product = v3["getProduct"]["data"]

export const ProductModalButtons = ({ productId }: { productId: string }) => {
  const product = useProduct(productId)

  if (!product) return null

  const { product_type } = product

  if (getIsPlayableV3(product)) {
    return <PlayableProductButtons product={product} />
  }

  switch (product_type.identifier) {
    case "app":
      return <AppProductButtons product={product} />
    case "activity":
      return null
    case "fast":
      return <FastProductButton product={product} />
    case "assessment":
      return <FastProductButton product={product} />
    case "process_on_web":
      return <FastProductButton product={product} />
    case "file":
      return <FileProductButton product={product} />
    case "link":
      return <LinkProductButton product={product} />
    case "meet":
      return null
    case "pdf":
      return <PdfProductButton product={product} />
    case "quiz_race":
      return <QuizRaceProductButton product={product} />
    case "video":
      return <VideoProductButton product={product} />
    case "xapi":
      return <XapiProductButton product={product} />
    default:
      return <div>"{product_type.identifier}" is not supported yet.</div>
  }
}

const XapiProductButton = ({ product }: { product: Product }) => {
  const { t } = useTranslation()

  const { content_link, scorm_strict_scoring, open_external_browser } = product

  const { link, isPending, error } = useLinkWithActiveSession(content_link)

  if (isPending) return
  if (error) return <ErrorPopup error={error} />

  const openInModal = scorm_strict_scoring && !open_external_browser

  const linkProps: Partial<PlayLinkProps> = openInModal
    ? { to: "xapi" }
    : { target: "_blank", href: link }

  return (
    <PlayLink product={product} {...linkProps}>
      {t("product.PLAY_TEXT_SIM")}
    </PlayLink>
  )
}

const VideoProductButton = ({ product }: { product: Product }) => {
  const { t } = useTranslation()
  const links = product.locale_content_links ?? []
  const { selectedLink, dropdown } = useLocalizedLinkDropdown(links)
  const locale = selectedLink?.locale

  return (
    <>
      {dropdown}
      <PlayLink
        product={product}
        to="video"
        search={(search) => ({ ...search, content_locale: locale })}
      >
        {t("product.PLAY_TEXT_VIDEO")}
      </PlayLink>
    </>
  )
}

const FastProductButton = ({ product }: { product: Product }) => {
  const { t } = useTranslation()
  const { content_link, open_external_browser } = product

  const openExternalLink = async () => {
    const link = await auth.getLinkWithActiveSession({ url: content_link })

    sendVuplexMessage({
      type: "OPEN_LINK",
      payload: { link, openExternalBrowser: true },
    })
  }

  if (IS_APP && open_external_browser) {
    return (
      <ProductModalMain.PlayLink onClick={openExternalLink}>
        {t("product.PLAY_TEXT_SIM")}
      </ProductModalMain.PlayLink>
    )
  }

  return (
    <PlayLink product={product} to="web-games">
      {t("product.PLAY_TEXT_SIM")}
    </PlayLink>
  )
}

const QuizRaceProductButton = ({ product }: { product: Product }) => {
  const { t } = useTranslation()
  return (
    <PlayLink
      product={product}
      to="web-games"
      onClick={() => createPlaythrough(product.id)}
    >
      {t("product.PLAY_TEXT_SIM")}
    </PlayLink>
  )
}

const PdfProductButton = ({ product }: { product: Product }) => {
  const { t } = useTranslation()
  const { id, file_uploads } = product
  const { selectedLink, dropdown } = useLocalizedLinkDropdown(file_uploads)
  const locale = selectedLink?.locale

  const { isSmallWindow } = useWindowWidth()
  const pdfSrc = getPreferredUploadV3(product, locale)

  const playLinkProps: FiestaLinkProps =
    isSmallWindow || IS_APP
      ? {
          href: pdfSrc,
          target: "_blank",
          onClick: () => createPlaythrough(id),
        }
      : {
          to: "pdf",
          search: (search) => ({ ...search, content_locale: locale }),
          onClick: () => createPlaythrough(id),
        }

  return (
    <>
      {dropdown}
      <PlayLink product={product} {...playLinkProps}>
        {t("product.PLAY_TEXT_PDF")}
      </PlayLink>
    </>
  )
}

const FileProductButton = ({ product }: { product: Product }) => {
  const { t } = useTranslation()
  const { id, content_link, file_uploads } = product
  const { selectedLink, dropdown } = useLocalizedLinkDropdown(file_uploads)

  return (
    <>
      {dropdown}
      <PlayLink
        product={product}
        href={selectedLink?.url ?? content_link}
        iconVariant="download"
        target="_blank"
        onClick={() => createPlaythrough(id)}
      >
        {t("product.DOWNLOAD_TEXT_SIM")}
      </PlayLink>
    </>
  )
}

const LinkProductButton = ({ product }: { product: Product }) => {
  const { t } = useTranslation()

  const links = product.locale_content_links ?? []
  const { selectedLink, dropdown } = useLocalizedLinkDropdown(links)
  const contentLink = getPreferredContentLink(product, selectedLink?.locale)

  const link = selectedLink?.link ?? contentLink

  // This is specifically for linking to Discourse forums
  // https://jira.attensi.com/browse/WEB-15665
  const isDiscourseLink =
    link === "https://api.attensi.com/api/public/discourse/callback"

  const getDiscourseLink = () => {
    return auth.getLinkWithActiveSession({ url: link })
  }

  return (
    <>
      {dropdown}
      <PlayLink
        product={product}
        target="_blank"
        href={isDiscourseLink ? undefined : link}
        getLink={isDiscourseLink ? getDiscourseLink : undefined}
        onClick={() => createPlaythrough(product.id)}
      >
        {t("product.PLAY_TEXT_LINK")}
      </PlayLink>
    </>
  )
}

const PlayableProductButtons = ({ product }: { product: Product }) => {
  const { t } = useTranslation()

  const [showLauncherOverlay, toggleLauncherOverlay] = useToggle()
  const [showHelpModal, toggleHelpModal] = useToggle()
  const [showDownloadHelpModal, toggleDownloadHelpModal] = useToggle()

  const {
    canInstallApp,
    canInstallWithVpp,
    canPlay,
    canPlayWebgl,
    canStartApp,
    canStartLauncher,
  } = getPlayCapabilites(product)

  if (!canPlay) {
    return <NotSupported product={product} />
  }

  const showDownloadHelpButton = canInstallApp && canStartApp
  const hasMultiplePlayButtons = canStartLauncher && canPlayWebgl
  const webglLinkProps: Partial<PlayLinkProps> = product.webgl_responsive
    ? { to: "play" }
    : { target: "_blank", getLink: getWebGLLink }

  return (
    <>
      {canInstallWithVpp && (
        <PlayLink product={product} iconVariant="download" to="vpp">
          {t("product.DOWNLOAD_TEXT_SIM")}
        </PlayLink>
      )}

      {canInstallApp && (
        <PlayLink
          product={product}
          href={getInstallAppLink(product)}
          target="_blank"
          iconVariant="download"
        >
          {t("product.DOWNLOAD_TEXT_SIM")}
        </PlayLink>
      )}

      {canStartApp && (
        <PlayLink product={product} getLink={getStartAppLink}>
          {t("product.PLAY_TEXT_SIM")}
        </PlayLink>
      )}

      {canPlayWebgl && (
        <PlayLink product={product} {...webglLinkProps}>
          {hasMultiplePlayButtons
            ? t("content.PRODUCT_WEBGL_LAUNCHER")
            : t("product.PLAY_TEXT_SIM")}
        </PlayLink>
      )}

      {canStartLauncher && (
        <PlayLink
          product={product}
          onClick={toggleLauncherOverlay}
          getLink={getLauncherLink}
          linkVariant={hasMultiplePlayButtons ? "white" : "green"}
        >
          {hasMultiplePlayButtons
            ? t("content.PRODUCT_DESKTOP_LAUNCHER")
            : t("product.PLAY_TEXT_SIM")}
        </PlayLink>
      )}

      {hasMultiplePlayButtons && (
        <ProductModalMain.HelpIcon onClick={toggleHelpModal} />
      )}
      {showHelpModal && <ProductModalHelpLoader onToggle={toggleHelpModal} />}

      {showDownloadHelpButton && (
        <ProductModalMain.HelpIcon onClick={toggleDownloadHelpModal} />
      )}
      {showDownloadHelpModal && (
        <ProductModalDownload onToggle={toggleDownloadHelpModal} />
      )}

      {showLauncherOverlay && (
        <LauncherOverlay
          textLine1={t("product.STARTSIM_DESC")}
          textLine2={t("settings.DOWNLOAD_LAUNCHER_PROMPT")}
          downloadText={t("settings.DOWNLOAD_LAUNCHER_PROMPT_2")}
          backText={t("product.STARTSIM_DESC_BACK")}
          downloadLink={getStaticLauncherLink()}
          onBack={toggleLauncherOverlay}
        />
      )}
    </>
  )
}

const AppProductButtons = ({ product }: { product: Product }) => {
  const { t } = useTranslation()
  const { id, content_link } = product

  if (!DEVICE_PLATFORM.isAppPlatform) {
    return <NotSupported product={product} />
  }

  const installAppLink = getInstallAppLink(product)
  const vuplexCanHandleDownload = IS_APP && Boolean(content_link)
  const showDownloadLink = installAppLink && !vuplexCanHandleDownload

  return (
    <>
      {showDownloadLink && (
        <PlayLink
          product={product}
          href={installAppLink}
          target="_blank"
          iconVariant="download"
          onClick={() => createPlaythrough(id)}
        >
          {t("product.DOWNLOAD_TEXT_SIM")}
        </PlayLink>
      )}

      {content_link && (
        <PlayLink
          product={product}
          href={content_link}
          target="_blank"
          onClick={() => createPlaythrough(id)}
        >
          {t("product.PLAY_TEXT_SIM")}
        </PlayLink>
      )}
    </>
  )
}

const NotSupported = ({ product }: { product: Product }) => {
  const { t } = useTranslation()
  const { platform } = DEVICE_PLATFORM
  const title = `${t("product.NOT_SUPPORTED_GENERIC")} ${platform}`
  const platforms = product.supported_platforms.join(" / ")

  return (
    <ProductModalMain.NotSupported
      title={title}
      supportedBy={t("product.SUPPORTED_PLATFORMS")}
      platforms={platforms}
    />
  )
}

type PlayLinkProps = ComponentProps<typeof ProductModalMain.PlayLink> & {
  product: Product
  getLink?: (product: Product) => Promise<string | undefined>
}
const PlayLink = ({
  product,
  getLink,
  onClick,
  href,
  ...props
}: PlayLinkProps) => {
  useEffect(() => {
    preloadPlayLinksData(product.id)
  }, [product])

  const shouldDelegateToVuplex =
    IS_APP && isHandledByVuplex(product.product_type.identifier)

  const handleClick = async () => {
    onClick?.()

    const dynamicLink = await getLink?.(product)

    if (shouldDelegateToVuplex) {
      if (dynamicLink) {
        sendOpenProductMessage(product, dynamicLink)
      } else if (href) {
        sendOpenProductMessage(product, href)
      }
    } else if (dynamicLink) {
      if (props.target === "_blank") {
        window.open(dynamicLink)
      } else {
        window.location.href = dynamicLink
      }
    }
  }

  return (
    <ProductModalMain.PlayLink
      {...props}
      onClick={handleClick}
      href={shouldDelegateToVuplex ? undefined : href}
    />
  )
}

const useLocalizedLinkDropdown = <
  T extends { locale: string; id: string | number }
>(
  localeLinks: T[]
) => {
  const { i18n } = useTranslation()

  const preferredLink = localeLinks.find(
    (localeLink) => localeLink.locale === i18n.language
  )
  const [selectedLink, setSelectedLink] = useState(
    preferredLink ?? localeLinks[0]
  )

  const options = localeLinks.map((localeLink) => (
    <Dropdown.Option key={localeLink.id} value={localeLink}>
      {getLocaleDisplayName(localeLink.locale)}
    </Dropdown.Option>
  ))

  const dropdown = selectedLink && !preferredLink && (
    <ProductModalMain.LanguageDropdown>
      <Dropdown value={selectedLink} onChange={setSelectedLink}>
        <Dropdown.Button>
          {getLocaleDisplayName(selectedLink.locale)}
        </Dropdown.Button>
        <Dropdown.Options>{options}</Dropdown.Options>
      </Dropdown>
    </ProductModalMain.LanguageDropdown>
  )

  return { selectedLink, dropdown }
}

const useProduct = (productId: string) => {
  return v3.getProduct.useQuery(
    [productId, { include: ["locale_content_links"] }],
    { select: (res) => res.data }
  )
}
