import { v3 } from "backoffice-api"
import i18next from "i18next"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { TEXT_FALLBACK } from "ui-tools"
import { isExactlyEqual, isPartiallyEqual, parseLocale } from "./Locale"

type TranslatedTexts = v3["getProduct"]["data"]["title"] | null | undefined
type CompanySettings = v3["getCurrentCompanySettings"]

export const usePickText = () => {
  const { i18n } = useTranslation()

  const locale = i18n.language

  const companySettings = v3.getCurrentCompanySettings.useQuery([])

  const pickTextCallback = useCallback(
    (texts: TranslatedTexts, fallback?: string) => {
      return pickTranslatedText({ texts, locale, companySettings, fallback })
    },
    [locale, companySettings]
  )

  return pickTextCallback
}

export const pickTextAsync = async (
  texts: TranslatedTexts,
  fallback?: string
) => {
  const companySettings = await v3.getCurrentCompanySettings.fetchQueryOnce([])
  const locale = i18next.language
  return pickTranslatedText({ texts, companySettings, fallback, locale })
}

type PickTranslatedTextProps = {
  texts: TranslatedTexts
  locale: string
  companySettings: CompanySettings | undefined
  fallback?: string
}
const pickTranslatedText = ({
  companySettings,
  texts,
  locale,
  fallback = TEXT_FALLBACK,
}: PickTranslatedTextProps) => {
  const textEntries = Object.entries(texts ?? {})
    .filter((entry): entry is [string, string] => Boolean(entry[1]))
    .map(([key, text]) => [parseLocale(key), text] as const)

  const parsedLocale = parseLocale(locale)
  for (const [locale, text] of textEntries) {
    if (isExactlyEqual(locale, parsedLocale)) {
      return text
    }
  }

  const browserLocales = window.navigator.languages.map(parseLocale)
  for (const browserLocale of browserLocales) {
    for (const [locale, text] of textEntries) {
      if (isPartiallyEqual(locale, browserLocale)) {
        return text
      }
    }
  }

  const defaultLocale = companySettings?.data.default_locale.code
  if (!defaultLocale) return fallback

  const parsedDefaultLocale = parseLocale(defaultLocale)
  for (const [locale, text] of textEntries) {
    if (isExactlyEqual(locale, parsedDefaultLocale)) {
      return text
    }
  }

  return fallback
}
