import avatarIcon from "assets/demo/avatar.svg"
import portalBackground from "assets/demo/portalBackground.svg"
import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query UserProfileView {
    currentUser {
      displayName
      username
      avatar
      position
      email
      phoneNumber
      anonymous
    }
    currentCompany {
      backgroundUrl
      userDisplayNameFormat
      companyFeatureFlags
      portalFeatureFlags
    }
  }
`)

export const mockUserProfileViewData = mockData<Data>({
  currentUser: {
    displayName: "Bob Kowalski",
    username: "...*^NightM45ter666^*...",
    avatar: avatarIcon,
    position: "Chief Executive Officer",
    email: "nightmaster666@yahoo.com",
    phoneNumber: "+47 123 45 67",
    anonymous: false,
  },
  currentCompany: {
    userDisplayNameFormat: "username",
    backgroundUrl: portalBackground,
    companyFeatureFlags: ["anonymous"],
    portalFeatureFlags: [],
  },
})

registerMockData(query, mockUserProfileViewData())
