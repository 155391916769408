import type { v1, v3 } from "backoffice-api"
import { DEVICE_PLATFORM } from "../constants"
import type { ProductTypeIdentifier } from "../graphql"

type ProductV1 = v1["getProduct"]
type ProductV3 = v3["getProduct"]["data"]

export const getInstallAppLink = (product: ProductV1 | ProductV3) => {
  const { platform } = DEVICE_PLATFORM
  const androidLink = product.google_play_test_link || product.google_play_link
  const iosLink = product.test_flight_link || product.apple_store_link

  if (platform === "Android" && androidLink) return androidLink
  if (platform === "iOS" && iosLink) return iosLink
}

export const getIsWebGamesProduct = (productType: ProductTypeIdentifier) => {
  const types: ProductTypeIdentifier[] = [
    "fast",
    "assessment",
    "process_on_web",
  ]
  return types.includes(productType)
}

export const getIsLeaderboardProduct = (productType: ProductTypeIdentifier) => {
  const types: ProductTypeIdentifier[] = [
    "behavior",
    "process",
    "skill",
    "operations",
    "workflow",
  ]
  return types.includes(productType)
}

export const getProductLink = (
  productId: number | string,
  productType: ProductTypeIdentifier
) => {
  if (getIsLeaderboardProduct(productType)) {
    return `sim/${productId}`
  }
  if (productType === "activity") {
    return `activity/${productId}`
  }
  if (productType === "meet") {
    return `event/${productId}`
  }
  return `product/${productId}`
}

// Version V1 of this isn't needed anywhere, but it's still
// useful to mark this one as V3 specific
export const getPreferredUploadV3 = (
  product: ProductV3,
  locale: string | undefined
) => {
  const { file_uploads, content_link } = product

  const preferredUpload = file_uploads.find(
    (upload) => upload.locale === locale
  )

  return preferredUpload?.url ?? file_uploads[0]?.url ?? content_link
}

// Version V1 of this isn't needed anywhere, but it's still
// useful to mark this one as V3 specific
export const getIsPlayableV3 = (product: ProductV3) => {
  return product.product_type.open_with === "launcher"
}

export const getPreferredContentLink = (
  product: ProductV3,
  locale: string | undefined
) => {
  const links = product.locale_content_links ?? []
  const preferredLink = links.find((link) => link.locale === locale)

  const contentLink =
    preferredLink?.link ??
    // If link in preferred locale does not exist, we fallback to any available locale.
    // User will be allowed to select a different link locale from the list.
    links[0]?.link ??
    // Older products won't have localized content links at all,
    // so we show the old content link instead.
    product.content_link

  return contentLink
}
