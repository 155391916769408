import { useLinkWithActiveSession } from "auth"
import { v3 } from "backoffice-api"
import { useModal, useRouteParamNumber } from "hooks"
import { Loader } from "materia"
import { useTranslation } from "react-i18next"
import { ErrorPopup } from "utility-components"
import { withQueryParams } from "utils"
import { usePickText } from "../../i18n/usePickText"
import { useIframeMessages } from "../../tools/useIframeMessages"
import { usePageTitle } from "../../tools/usePageTitle"
import { useBlockConfettiBanner } from "../ConfettiBanner/useBlockConfettiBanner"
import { ViewerModal } from "./ViewerModal"

type Product = v3["getProduct"]["data"]

export const WebGamesModalLoader = ({ closeRoute }: { closeRoute: string }) => {
  const productId = useRouteParamNumber("productId")

  useBlockConfettiBanner()

  const { onClose } = useModal(closeRoute)

  const product = v3.getProduct.useQuery([productId], {
    select: (res) => res.data,
  })

  return (
    <ViewerModal onClose={onClose}>
      {product ? <Load product={product} onClose={onClose} /> : <Loader />}
    </ViewerModal>
  )
}

const Load = ({
  product,
  onClose,
}: {
  product: Product
  onClose: () => void
}) => {
  const pickText = usePickText()
  const { i18n } = useTranslation()

  const title = pickText(product.title)
  usePageTitle(title)

  useIframeMessages({
    onClose,
    targetUrl: product.content_link,
  })

  const url = withQueryParams(product.content_link, { locale: i18n.language })

  const { link, isPending, error } = useLinkWithActiveSession(url)

  if (isPending) return <Loader />
  if (error) return <ErrorPopup error={error} />

  return (
    <ViewerModal.FullScreen src={link} headerText={title} onClose={onClose} />
  )
}
