import certificateBadgeLocked from "assets/icons/certificateBadgeLocked.svg"
import certificateBadgeUnlocked from "assets/icons/certificateBadgeUnlocked.svg"
import { useInfiniteGraphqlQuery } from "graphql-mock"
import { last, times } from "lodash-es"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { ErrorPopup, FiestaLink } from "utility-components"
import { UserProfileSection } from "../UserProfileSection"
import { query, type Product } from "./gql"

import s from "./styles.module.scss"

const ITEMS_PER_PAGE = 6

export const UserProfileCertificates = () => {
  const { t } = useTranslation()
  const { data, error, isFetchingNextPage, fetchNextPage } =
    useInfiniteGraphqlQuery(
      query,
      { limit: ITEMS_PER_PAGE },
      {
        getNextPage: (data) => data.products.pageInfo.nextPage,
      }
    )

  if (!data) return null
  if (error) return <ErrorPopup error={error} />

  const products = data.pages.flatMap((page) => page.products.nodes)

  if (!products.length) return null

  const pageInfo = last(data.pages)?.products.pageInfo
  const hasMoreCertificates = pageInfo?.hasNextPage && !isFetchingNextPage

  return (
    <UserProfileSection title={t("userProfile.SECTION_CERTIFICATES")}>
      <div className={s.certificates}>
        {products.map((product) => (
          <Certificate key={product.id} product={product} />
        ))}
        {isFetchingNextPage && (
          <CertificateLoading count={pageInfo?.nextPageItemCount} />
        )}
      </div>
      {hasMoreCertificates && <ShowMore onClick={fetchNextPage} />}
    </UserProfileSection>
  )
}

const Certificate = ({ product }: { product: Product }) => {
  const { id, deadlineOccurrence, diplomaConfiguration } = product

  const isUnlocked =
    deadlineOccurrence?.status === "certified" ||
    deadlineOccurrence?.status === "certified_after_deadline"

  return (
    <div className={s.certificate}>
      <FiestaLink to={`diploma/${id}`} className={s.certificate__content}>
        <div className={modifiers(s, "certificate__diploma", { isUnlocked })}>
          {isUnlocked ? (
            <img src={certificateBadgeUnlocked} alt="Unlocked" />
          ) : (
            <img src={certificateBadgeLocked} alt="Locked" />
          )}
        </div>
        <div className={modifiers(s, "certificate__title", { isUnlocked })}>
          {diplomaConfiguration?.title}
        </div>
      </FiestaLink>
    </div>
  )
}

const CertificateLoading = ({ count }: { count?: number }) => {
  return times(count ?? ITEMS_PER_PAGE, () => (
    <div className={s.certificate}>
      <div className={s.certificate__content}>
        <div className={s.skeleton__diploma} />
        <div className={s.skeleton__title} />
      </div>
    </div>
  ))
}

const ShowMore = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation()

  return (
    <button className={s.showMore} onClick={onClick}>
      {t("userProfile.SHOW_MORE")}
    </button>
  )
}
