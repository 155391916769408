import type { Consumer } from "@rails/actioncable"
import { auth } from "auth"
import { BACKOFFICE_API_URL } from "../env"

type Channel = "PlaythroughProcessedChannel"

let consumer: Promise<Consumer> | undefined

export const subscribeToChannel = async <T>(
  channel: Channel,
  onMessage: (message: unknown) => void
) => {
  const consumer = await getConsumer()

  consumer.subscriptions.create(channel, {
    received: onMessage,
  })
}

const getConsumer = async () => {
  if (!consumer) {
    consumer = createConsumer()
  }

  return consumer
}

const createConsumer = async () => {
  const url = await auth.getLinkWithActiveSession({
    url: `${BACKOFFICE_API_URL}/cable`,
  })

  const { createConsumer } = await import("@rails/actioncable")
  return createConsumer(url)
}
