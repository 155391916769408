import chevronIcon from "assets/icons/chevronIcon.svg"
import { useGraphqlQuery } from "graphql-mock"
import { ProgressBar } from "materia"
import { ErrorPopup } from "utility-components"
import { Badge } from "../../../components/Badge"
import { useNavigateBack } from "../../../tools/useNavigateBack"
import { query } from "./gql"
import { BundleHeaderLoading } from "./loading"
import s from "./styles.module.scss"

export const BundleHeader = ({ id }: { id: string }) => {
  const { data, error, isPending } = useGraphqlQuery(query, { id })

  const navigateBack = useNavigateBack()

  if (isPending) return <BundleHeaderLoading />
  if (error) return <ErrorPopup error={error} />

  const { progress, title } = data.productBundle

  return (
    <div className={s.header}>
      <div className={s.header__centered}>
        <div className={s.header__widgetLayoutSize}>
          <button className={s.header__back} onClick={() => navigateBack()}>
            <img className={s.header__backIcon} src={chevronIcon} alt="" />
          </button>
          <div className={s.header__title}>{title}</div>
          <div className={s.header__progress}>
            <ProgressBar
              value={progress.certifiedCount}
              maxValue={progress.certifiedMax}
              color="purple-60"
              backgroundColor="purple-10"
            />
          </div>
          <div className={s.header__stars}>
            <Badge
              title={`${progress.starsCount}/${progress.starsMax}`}
              type="bundle"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
