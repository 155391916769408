import { formatNumber } from "format"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { toPercent } from "utils"
import s from "./styles.module.scss"

export const LeaderboardEntry = ({
  image,
  isFirst,
  maxScore,
  name,
  rank,
  score,
}: {
  image: string
  isFirst: boolean
  maxScore: number
  name: string
  rank: number
  score: number
}) => {
  const { i18n } = useTranslation()

  return (
    <li className={modifiers(s, "entry", { isFirst })}>
      <p className={s.entry__rank}>{rank}</p>
      <img
        loading="lazy"
        className={s.entry__image}
        src={image}
        alt="User icon"
      />
      <Progress
        isFirst={isFirst}
        name={name}
        score={score}
        scoreFormatted={formatNumber(score, i18n.language)}
        maxScore={maxScore}
      />
    </li>
  )
}

const Progress = ({
  isFirst,
  maxScore,
  name,
  score,
  scoreFormatted,
}: {
  isFirst: boolean
  maxScore: number
  name: string
  score: number
  scoreFormatted: string
}) => (
  <div className={modifiers(s, "progress", { isFirst })}>
    <div className={s.progress__header}>
      <p className={s.progress__name}>{name}</p>
      <p className={s.progress__score}>{scoreFormatted}</p>
    </div>
    <div
      className={s.progress__line}
      style={{ width: `${toPercent(score, maxScore)}%` }}
    />
  </div>
)

export const LeaderboardEntryLoading = () => (
  <div className={s.skeleton}>
    <div className={s.skeleton__rank} />
    <div className={s.skeleton__image} />
    <div className={s.skeleton__progress} />
  </div>
)
