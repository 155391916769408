import lock from "assets/icons/lock.svg"
import starEmpty from "assets/icons/starEmpty.svg"
import starFilled from "assets/icons/starFilled.svg"
import { times } from "lodash-es"
import s from "./styles.module.scss"

export const ProductCardStars = ({
  starsCount,
  starsMax,
  isLocked,
}: {
  starsCount: number
  starsMax: number
  isLocked: boolean
}) => {
  if (isLocked) return <Locked />

  return (
    <div className={s.stars} aria-label={`${starsCount} of ${starsMax} stars`}>
      {times(starsMax, (index) => (
        <img
          key={index}
          src={index < starsCount ? starFilled : starEmpty}
          alt=""
        />
      ))}
    </div>
  )
}

const Locked = () => {
  return (
    <div className={s.locked}>
      <img className={s.locked__icon} src={lock} alt="locked" />
    </div>
  )
}
