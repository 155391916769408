import { useGraphqlQuery } from "graphql-mock"
import { useScrollToTopOnMount } from "hooks"
import { useTranslation } from "react-i18next"
import { ErrorPopup } from "utility-components"
import { getQueryParams } from "utils"
import { usePageTitle } from "../../tools/usePageTitle"
import { UserProfileCertificates } from "./UserProfileCertificates"
import { UserProfileSection } from "./UserProfileSection"
import { UserProfileSettings } from "./UserProfileSettings"
import { query, type Data } from "./gql"

import s from "./styles.module.scss"

// TODO [WEB-16980] Lock diplomas behind a query param
const isDiplomasEnabledQueryParam =
  getQueryParams().enableDiplomas?.toUpperCase() === "TRUE"

export const UserProfileView = ({
  isDiplomasEnabled = isDiplomasEnabledQueryParam,
}: {
  isDiplomasEnabled?: boolean
}) => {
  useScrollToTopOnMount()

  const { t } = useTranslation()
  usePageTitle(t("pageTitle.YOUR_PROFILE"))

  const { data, error, isPending } = useGraphqlQuery(query, {}, { gcTime: 0 })

  if (error) return <ErrorPopup error={error} />

  return (
    <div className={s.userProfileView}>
      {isPending ? (
        <UserProfileLoading />
      ) : (
        <>
          <UserCard data={data} />
          {/* TODO [WEB-16980] Lock diplomas behind a query param */}
          {isDiplomasEnabled && <UserProfileCertificates />}
          <UserProfileSettings data={data} />
        </>
      )}
    </div>
  )
}

const UserCard = ({ data }: { data: Data }) => {
  const { avatar, displayName, position } = data.currentUser
  const { backgroundUrl } = data.currentCompany

  return (
    <div className={s.userCard}>
      {backgroundUrl && (
        <img src={backgroundUrl} className={s.userCard__cover} alt="" />
      )}
      <div className={s.userCard__info}>
        <img className={s.userCard__avatar} src={avatar} alt="" />
        <h1 className={s.userCard__name}>
          <div className={s.userCard__displayName}>{displayName}</div>
          <div className={s.userCard__department}>{position}</div>
        </h1>
      </div>
    </div>
  )
}

const UserProfileLoading = () => {
  return (
    <>
      <div className={s.userCard}>
        <div className={s.userCard__info}>
          <div className={s.userCard__avatar} />
          <h1 className={s.userCard__name}>
            <div className={s.skeleton__displayName} />
            <div className={s.skeleton__department} />
          </h1>
        </div>
      </div>
      <UserProfileSection title={<div className={s.skeleton__title} />}>
        <div className={s.skeleton__setting} />
        <div className={s.skeleton__setting} />
        <div className={s.skeleton__setting} />
      </UserProfileSection>
    </>
  )
}
