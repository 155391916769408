import {
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from "@tanstack/react-query"
import type { FnBase, FnParams, FnReturn } from "./types"
import { getArgs } from "./utils/getArgs"

type BaseInitialData<Fn extends FnBase> =
  | FnReturn<Fn>
  | (() => FnReturn<Fn>)
  | undefined

type Options<Fn extends FnBase, TData, InitialData> = Omit<
  UseQueryOptions<FnReturn<Fn>, Error, TData>,
  "queryFn" | "queryKey" | "initialData"
> & {
  initialData?: InitialData
}

export type UseQuery<Fn extends FnBase> = <
  TData = FnReturn<Fn>,
  InitialData extends BaseInitialData<Fn> = undefined
>(
  args: FnParams<Fn>,
  options?: Options<Fn, TData, InitialData>
) => UseQueryResult<TData>["data"]

export const getUseQuery = (fn: FnBase, path: string[]): UseQuery<FnBase> => {
  return (...input) => {
    const { args = [], rest } = getArgs(input)
    const [options] = rest
    const key = [...path, ...args]

    const result = useQuery<any>({
      throwOnError: true,
      ...options,
      queryKey: key,
      queryFn: () => fn(...args),
    })

    return result.data
  }
}
