import vinmonopoletLogo from "assets/demo/vinmonopoletLogo.png"
import attensiLogo from "assets/logos/attensiLogoBlue.svg"
import { mock } from "mock"
import * as authApiProd from "./auth"

const notMockable = () => {
  throw new Error("Non mockable")
}

export const authApiMock = mock(authApiProd, (mocks) => ({
  getSessions: () => [
    {
      accessToken: "12341",
      companyNameKey: "attensi",
      logo: attensiLogo,
      subdomain: "attensi",
      userId: 0,
      pickedLocale: "en-US",
      impersonation: false,
      environment: "test",
      rememberMe: false,
    },
    {
      accessToken: "12342",
      companyNameKey: "attensi",
      logo: attensiLogo,
      subdomain: "attensi",
      userId: 0,
      pickedLocale: "en-US",
      impersonation: false,
      environment: "test",
      rememberMe: false,
    },
    {
      accessToken: "12343",
      companyNameKey: "vinmonopolet",
      logo: vinmonopoletLogo,
      subdomain: "vinmonopolet",
      userId: 0,
      pickedLocale: "en-US",
      impersonation: false,
      environment: "test",
      rememberMe: false,
    },
  ],

  getActiveSession: () => mocks.getSessions()[0]!,

  getLinkWithActiveSession: () => "",

  getSubdomainFromPath: () => "",
  initializeAuth: (): undefined => undefined,
  logoutAndGetNext: () => mocks.getActiveSession(),
  accessTokenToTransferKey: () => "",
  updateConfig: () => {},

  redirectWithActiveSession: notMockable,
  loginNewSession: notMockable,
  logoutAndLogin: notMockable,
  logoutAndSLORedirect: notMockable,
  refreshLogin: notMockable,
}))
