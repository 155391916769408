import { useLinkWithActiveSession } from "auth"
import { v3 } from "backoffice-api"
import { useModal, useRouteParamNumber } from "hooks"
import { Loader } from "materia"
import { useEffect } from "react"
import { ErrorPopup, QueryBoundary } from "utility-components"
import { usePickText } from "../../i18n/usePickText"
import { usePageTitle } from "../../tools/usePageTitle"
import { sendVuplexMessage } from "../../vuplex/sendVuplexMessage"
import { useBlockConfettiBanner } from "../ConfettiBanner/useBlockConfettiBanner"
import { ViewerModal } from "./ViewerModal"

type XapiModalLoaderProps = {
  closeRoute: string
}
export const XapiModalLoader = ({ closeRoute }: XapiModalLoaderProps) => {
  const productId = useRouteParamNumber("productId")

  useAutoRotation()
  useBlockConfettiBanner()

  const { onClose } = useModal(closeRoute)

  return (
    <ViewerModal onClose={onClose}>
      <QueryBoundary fallback={<Loader />}>
        <Load productId={productId} onClose={onClose} />
      </QueryBoundary>
    </ViewerModal>
  )
}

type LoadProps = {
  productId: number
  onClose: () => void
}
const Load = ({ productId, onClose }: LoadProps) => {
  const pickText = usePickText()

  const product = v3.getProduct.useSuspenseQuery([productId], {
    select: (res) => res.data,
  })

  const title = pickText(product.title)
  usePageTitle(title)

  const { content_link } = product
  const { link, isPending, error } = useLinkWithActiveSession(content_link)

  if (isPending) return <Loader />
  if (error) return <ErrorPopup error={error} />

  return (
    <ViewerModal.FullScreen headerText={title} src={link} onClose={onClose} />
  )
}

const useAutoRotation = () => {
  useEffect(() => {
    sendVuplexMessage({
      type: "SET_SCREEN_ORIENTATION",
      payload: { screenOrientation: "AUTO" },
    })

    return () => {
      sendVuplexMessage({
        type: "SET_SCREEN_ORIENTATION",
        payload: { screenOrientation: "PORTRAIT" },
      })
    }
  }, [])
}
