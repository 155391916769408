import { useNavigate } from "@tanstack/react-location"
import { v1 } from "backoffice-api"
import { invalidateAllQueries } from "bonzai"
import { showErrorPopup } from "error-popup"
import { isFetchError } from "fetcher"
import { t } from "i18next"
import { Button, Input, Toggle } from "materia"
import { forwardRef, useId, type InputHTMLAttributes } from "react"
import {
  useForm,
  type UseFormRegister,
  type UseFormSetError,
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { sendVuplexMessage } from "../../../vuplex/sendVuplexMessage"
import { UserProfileSection } from "../UserProfileSection"
import { type Data } from "../gql"

import s from "./styles.module.scss"

type FormDataType = {
  avatarId: string | null
  userName: string | null
  phoneNumber: string
  showMeOnLeaderboards: boolean
}
export const UserProfileSettings = ({ data }: { data: Data }) => {
  const { t } = useTranslation()

  const { currentUser, currentCompany } = data

  const defaultValues: FormDataType = {
    avatarId: null,
    userName: currentUser.username ?? "",
    phoneNumber: currentUser.phoneNumber ?? "",
    showMeOnLeaderboards: !currentUser.anonymous,
  }
  const form = useForm({ defaultValues })
  const { register, formState, handleSubmit, setError } = form
  const { errors, isSubmitting, isDirty } = formState

  const isUsernameEnabled = currentCompany.userDisplayNameFormat === "username"
  const isUsernameEditDisabled = currentCompany.portalFeatureFlags.includes(
    "disable_username_editing"
  )
  const isPhoneNumberEditEnabled = currentCompany.portalFeatureFlags.includes(
    "enable_phone_number_editing"
  )
  const isAnonymousEnabled =
    currentCompany.companyFeatureFlags.includes("anonymous")

  const onSubmit = useOnSubmit(setError)

  return (
    <UserProfileSection title={t("userProfile.SECTION_PROFILE")}>
      <form className={s.userSettingsProfile} onSubmit={handleSubmit(onSubmit)}>
        {isAnonymousEnabled && (
          <Toggle
            label={t("userProfile.NOT_ANONYMOUS")}
            {...register("showMeOnLeaderboards")}
          />
        )}
        {isUsernameEnabled && (
          <Input
            label={t("userProfile.USERNAME")}
            errorMessage={errors.userName?.message}
            disabled={isUsernameEditDisabled}
            {...register("userName", { validate: validateUserName })}
          />
        )}
        <Input
          label={t("userProfile.MAIL")}
          disabled
          value={currentUser.email ?? ""}
        />
        <Input
          label={t("userProfile.MOBILE")}
          disabled={!isPhoneNumberEditEnabled}
          errorMessage={errors.phoneNumber?.message}
          {...register("phoneNumber", { validate: validatePhoneNumber })}
        />
        <AvatarList register={register} />
        <Button type="submit" disabled={isSubmitting || !isDirty}>
          {t("userProfile.SAVE")}
        </Button>
      </form>
    </UserProfileSection>
  )
}

const AvatarList = ({
  register,
}: {
  register: UseFormRegister<FormDataType>
}) => {
  const { t } = useTranslation()
  const avatars = useAvatars()

  const avatarElements = avatars.map((avatar) => (
    <Avatar
      key={avatar.id}
      src={avatar.url}
      value={avatar.id}
      alt={avatar.title}
      {...register("avatarId")}
    />
  ))

  return (
    <div className={s.avatars}>
      <label className={s.avatars__label}>
        {t("userProfile.CHOOSE_AVATAR")}
      </label>
      <ul className={s.avatars__avatars} tabIndex={0}>
        {avatarElements}
      </ul>
    </div>
  )
}

type AvatarProps = InputHTMLAttributes<HTMLInputElement> & {
  src: string
  alt: string
}
const Avatar = forwardRef<HTMLInputElement, AvatarProps>(
  ({ src, alt, ...props }, ref) => {
    const id = useId()

    return (
      <li className={s.avatar}>
        <input
          type="radio"
          id={id}
          className={s.avatar__input}
          ref={ref}
          {...props}
        />
        <label className={s.avatar__label} htmlFor={id}>
          <img className={s.avatar__image} src={src} alt={alt} loading="lazy" />
        </label>
      </li>
    )
  }
)

// TODO [WEB-16831] move to GraphQL
const useOnSubmit = (setError: UseFormSetError<FormDataType>) => {
  const navigate = useNavigate()

  const updateUser = async (data: FormDataType) => {
    await v1.updateUser({
      anonymous: !data.showMeOnLeaderboards,
      username: data.userName,
      phone_number: data.phoneNumber,
      avatar_id: data.avatarId !== null ? data.avatarId : undefined,
    })

    sendVuplexMessage({ type: "USER_SETTINGS_UPDATE" })

    invalidateAllQueries()

    navigate({ to: "/for-you" })
  }

  return async (data: FormDataType) => {
    try {
      await updateUser(data)
    } catch (error) {
      if (isFetchError(error) && (error.json as any).username) {
        setError("userName", { message: (error.json as any).username[0] })
      } else if (isFetchError(error) && (error.json as any).phone_number) {
        setError("phoneNumber", {
          message: (error.json as any).phone_number[0],
        })
      } else {
        showErrorPopup({ error })
      }
    }
  }
}

const useAvatars = () => {
  // TODO [WEB-16831] move to GraphQL
  const avatars = v1.getAvatars.useSuspenseQuery([], {
    select: (res) => res.flatMap((group) => group.avatars),
  })
  return avatars
}

// TODO [WEB-16831] move to GraphQL
const validateUserName = async (userName: string | null) => {
  if (userName === null) return

  const me = await v1.getMe.fetchQueryOnce([])

  if (me.username === userName) return

  if (userName === "") return t("onboarding.USERNAME_EXISTS")

  const { available } = await v1.getIsUsernameAvailable(userName)
  if (!available) return t("onboarding.USERNAME_EXISTS")
}

const validatePhoneNumber = (phoneNumber: string) => {
  const isValid = phoneNumber.startsWith("+") || phoneNumber.startsWith("00")
  if (!isValid) return t("userProfile.INVALID_PHONE_NUMBER")
}
