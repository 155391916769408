import { Dialog } from "materia"
import s from "./LauncherOverlay.module.scss"

type LauncherOverlayProps = {
  textLine1: string
  textLine2: string
  downloadText: string
  backText: string
  onBack: () => void
  downloadLink: string
}
export const LauncherOverlay = ({
  backText,
  downloadText,
  textLine1,
  textLine2,
  downloadLink,
  onBack,
}: LauncherOverlayProps) => (
  <Dialog ariaLabel="Play in Attensi Launcher" onClose={onBack}>
    <div className={s.launcherOverlay}>
      <div>{textLine1}</div>
      <div>
        {textLine2}{" "}
        <a className={s.launcherOverlay__link} href={downloadLink}>
          {downloadText}
        </a>
      </div>
      <button onClick={onBack}>&lt; {backText}</button>
    </div>
  </Dialog>
)
