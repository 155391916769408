import activityLeaderboardIcon from "assets/icons/activityLeaderboardIcon.svg"
import arrow from "assets/icons/arrowRight.svg"
import completedIcon from "assets/icons/completedIcon.svg"
import starFilled from "assets/icons/starFilled.svg"
import { useGraphqlQuery } from "graphql-mock"
import type { TFunction } from "i18next"
import { useWindowWidth } from "materia"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { ErrorPopup, FiestaImage, FiestaLink } from "utility-components"
import { exists } from "utils"
import { useGetOrdinal } from "../../../dataUtilities/getOrdinal"
import { query, type RecentActivityEvent } from "./gql"
import s from "./styles.module.scss"

export const RecentActivityWidgetMobile = () => {
  const { isBigWindow } = useWindowWidth()
  const { t } = useTranslation()

  const { data, error, isPending } = useGraphqlQuery(
    query,
    {},
    { enabled: !isBigWindow }
  )

  const events = [data?.completed, data?.leaderboard, data?.stars]
    .map((data) => data?.nodes[0])
    .filter(exists)

  const activeEntry = useActiveEntry(events.length)

  if (isBigWindow) return
  if (isPending) return <RecentActivityWidgetMobileLoading />
  if (error) return <ErrorPopup error={error} />

  const { currentCompany } = data
  const { portalFeatureFlags } = currentCompany

  if (!portalFeatureFlags.includes("enable_activity_feed")) return

  return (
    <div className={s.recentActivityWidget}>
      <div className={s.widget}>
        {events.length === 0 ? (
          <div className={s.widget__empty}>
            {t("activityFeed.no_activities")}
          </div>
        ) : (
          events.map((event, index) => (
            <Entry key={index} event={event} isActive={activeEntry === index} />
          ))
        )}
      </div>
    </div>
  )
}

const Entry = ({
  event,
  isActive,
}: {
  event: RecentActivityEvent
  isActive: boolean
}) => {
  const { t } = useTranslation()
  const getOrdinal = useGetOrdinal()

  const { user, product, createdAt, eventType, rank, stars } = event

  const getSummaryText = () => {
    if (eventType === "stars" && stars)
      return t("activityFeed.stars", { count: stars })
    if (eventType === "leaderboard" && rank) return getOrdinal(rank)
    return t("activityFeed.completed")
  }

  const entryClass = modifiers(s, "entry", {
    orange: eventType === "leaderboard",
    green: eventType === "completed",
    isActive,
  })

  const getIcon = () => {
    if (eventType === "completed") return completedIcon
    if (eventType === "leaderboard") return activityLeaderboardIcon
    if (eventType === "stars") return starFilled
  }

  return (
    <FiestaLink to="recent-activity-widget" className={entryClass}>
      <img className={s.entry__avatar} src={user.avatar} alt="" />
      <div className={s.entry__userSummary}>
        <div className={s.entry__summaryTop}>
          <div className={s.entry__name}>{user.displayName}</div>
          <div className={s.entry__time}>{displayTimestamp(createdAt, t)}</div>
        </div>
        <div className={s.entry__summaryBottom}>
          <img className={s.entry__icon} src={getIcon()} alt="" />
          <div className={s.entry__summaryText}>{getSummaryText()}</div>
          <div className={s.entry__productTitle}>{product.title}</div>
        </div>
      </div>

      <div className={s.entry__arrowContainer}>
        <FiestaImage
          className={s.entry__arrow}
          src={arrow}
          alt=""
          sizes="20px"
        />
      </div>
    </FiestaLink>
  )
}

export const RecentActivityWidgetMobileLoading = () => {
  return (
    <div className={s.skeleton}>
      <div className={s.skeleton__avatar} />
      <div className={s.skeleton__userSummary}>
        <div className={s.skeleton__title} />
        <div className={s.skeleton__summary} />
      </div>
      <div className={s.skeleton__arrow} />
    </div>
  )
}

const useActiveEntry = (numberOfEntries: number) => {
  const [activeEntry, setActiveEntry] = useState(0)

  useEffect(() => {
    const onInterval = () => {
      setActiveEntry((activeEntry) => {
        if (activeEntry + 1 >= numberOfEntries) return 0
        return activeEntry + 1
      })
    }
    const interval = window.setInterval(onInterval, 6000)

    return () => window.clearInterval(interval)
  }, [numberOfEntries])

  return activeEntry
}

const displayTimestamp = (timestamp: string, t: TFunction) => {
  const time = new Date(timestamp)
  const now = new Date()
  const difference = now.getTime() - time.getTime()

  const ONE_HOUR = 1000 * 60 * 60
  const ONE_DAY = ONE_HOUR * 24
  const THREE_DAYS = ONE_DAY * 3

  const hours = Math.floor(difference / ONE_HOUR)
  const days = Math.floor(difference / ONE_DAY)

  if (difference < ONE_HOUR) return t("date.JUST_NOW")
  if (difference < ONE_DAY) return hours + t("date.HOUR_SHORT")
  if (difference < THREE_DAYS) return days + t("date.DAY_SHORT")
  return t("date.A_WHILE_AGO")
}
