import { useEffect, useRef, useState } from "react"
import ReactPlayer, { type Config } from "react-player"
import s from "./styles.module.scss"

// List of players that react-player supports
// https://github.com/cookpete/react-player?tab=readme-ov-file#config-prop
const CUSTOMIZED_PLAYERS = ["vimeo", "youtube", "youtu.be"]
// Fixes a bug where video enters an infinite loop if user tries to seek
// before watching the first 1.2s of the video. If we set the first
// timestamp to 1.2s then the bug will not occur.
// https://jira.attensi.com/browse/WEB-17416
const FIRST_TIMESTAMP = 1.2

type VideoPlayerProps = {
  src: string
  isSeekAheadDisabled: boolean
  setShouldCreatePlaythrough: (value: boolean) => void
}
export const VideoPlayer = (props: VideoPlayerProps) => {
  const { src, setShouldCreatePlaythrough } = props

  const isCustomizedPlayer = CUSTOMIZED_PLAYERS.some((player) =>
    src.includes(player)
  )

  if (isCustomizedPlayer) {
    return <CustomPlayer {...props} />
  } else {
    setShouldCreatePlaythrough(true)
    return (
      <iframe
        className={s.iframe}
        src={src}
        title="Attensi product viewer"
        allow="autoplay; encrypted-media"
        allowFullScreen
      />
    )
  }
}

const CustomPlayer = ({
  src,
  isSeekAheadDisabled,
  setShouldCreatePlaythrough,
}: VideoPlayerProps) => {
  const PROGRESS_INTERVAL = 100 // how often we save video progress (ms)
  const secondsWatched = useRef(FIRST_TIMESTAMP)
  const playerRef = useRef<ReactPlayer>(null)
  const [player, setPlayer] = useState<ReactPlayer>()

  useEffect(() => {
    if (!playerRef.current) return
    setPlayer(playerRef.current)
  }, [])

  const onProgress = ({
    played,
    playedSeconds,
  }: {
    played: number
    playedSeconds: number
  }) => {
    if (!player) return
    if (!isSeekAheadDisabled) return
    if (playedSeconds < FIRST_TIMESTAMP) return

    // If video progress skips for more than defined value, we assume user
    // is seeking. This value must be bigger than PROGRESS_INTERVAL,
    // otherwise we risk false positives when lag occurs.
    // Less than 0.6 can cause stuttering on slow connections.
    const seekTreshold = 0.75
    const isPlayingForwards = secondsWatched.current < playedSeconds
    const isSeeking =
      Math.abs(secondsWatched.current - playedSeconds) > seekTreshold

    // Save timestamp only if video is playing forwards on its own
    if (isPlayingForwards && !isSeeking) {
      secondsWatched.current = playedSeconds
    }
    // Return to saved timestamp when user tries to seek ahead
    if (isPlayingForwards && isSeeking) {
      player.seekTo(secondsWatched.current)
    }

    // Mark video as watched if user watched more than 95%
    if (!isSeeking && played >= 0.95) {
      setShouldCreatePlaythrough(true)
    }
  }

  return (
    <div className={s.iframe}>
      <ReactPlayer
        ref={playerRef}
        url={src}
        progressInterval={PROGRESS_INTERVAL}
        onProgress={onProgress}
        loop={false}
        playing
        playsinline
        controls
        width="100%"
        height="100%"
        config={config}
      />
    </div>
  )
}

const config: Config = {
  youtube: { embedOptions: { autoplay: 1 } },
  vimeo: { playerOptions: { autoplay: 1, loop: 0 } },
}
