import { focusManager, type QueryOptions } from "@tanstack/react-query"
import { QueryClient } from "bonzai"
import { showErrorPopup } from "error-popup"
import { isFetchError } from "fetcher"

const onError = (error: unknown) => {
  showErrorPopup({ error })
}

const onRetry: QueryOptions["retry"] = (failureCount, error) => {
  const possibleNetworkError = isFetchError(error) && error.didRequestFail
  if (possibleNetworkError) return failureCount < 3

  return false
}

export const createQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: onRetry,
      },
      mutations: {
        onError,
      },
    },
  })
}

// The default focus manager only listens to visibilitychange
// focus and blur are useful with multiple monitors since the window can be visible but not focused
focusManager.setEventListener((handleFocus) => {
  const onFocus = () => handleFocus(true)
  const onBlur = () => handleFocus(false)

  const onVisibilityChange = () => {
    handleFocus(document.visibilityState === "visible")
  }

  window.addEventListener("focus", onFocus)
  window.addEventListener("blur", onBlur)
  window.addEventListener("visibilitychange", onVisibilityChange)

  return () => {
    window.removeEventListener("focus", onFocus)
    window.removeEventListener("blur", onBlur)
    window.removeEventListener("visibilitychange", onVisibilityChange)
  }
})
