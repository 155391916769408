import { useInfiniteGraphqlQuery } from "graphql-mock"
import { last } from "lodash-es"
import { ErrorPopup, WhenVisible } from "utility-components"
import { ProductCard } from "../../../components/ProductCard"
import { ProductCardsLoading } from "../../../components/ProductCard/loading"
import { ProductList } from "../../../components/ProductList"
import { query } from "./gql"
import { BundleProductsLoading, ITEMS_PER_PAGE } from "./loading"

export const BundleProducts = ({ id }: { id: string }) => {
  const { data, error, fetchNextPage, isFetchingNextPage } =
    useInfiniteGraphqlQuery(
      query,
      { id, limit: ITEMS_PER_PAGE },
      { getNextPage: (data) => data.productBundle.products.pageInfo.nextPage }
    )

  if (error) return <ErrorPopup error={error} />
  if (!data) return <BundleProductsLoading />

  const pageInfo = last(data.pages)?.productBundle.products.pageInfo
  if (!pageInfo) throw new Error("There must be at least one page")

  const products = data.pages.flatMap(
    (page) => page.productBundle.products.nodes
  )

  return (
    <>
      <ProductList>
        {products.map((data) => (
          <ProductCard key={data.id} data={data} />
        ))}
        {isFetchingNextPage && (
          <ProductCardsLoading count={pageInfo.nextPageItemCount} />
        )}
      </ProductList>

      <WhenVisible
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        key={products.length}
      />
    </>
  )
}
