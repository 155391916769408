import productImage from "assets/demo/productImage.svg"
import { mockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof journeyCardFragment>

export const journeyCardFragment = graphql(`
  fragment JourneyCard on Product @_unmask {
    id
    image
    productType
    isScorable
    title
    isMandatory

    activityProgress {
      progressCount
      progressOf
    }

    progress {
      inProgress
      starsCount
      starsMax
      isCertified
    }

    course {
      isAttending
    }
  }
`)

export const mockJourneyCardData = mockData<Data>({
  id: "1",
  image: productImage,
  productType: "pdf",
  isScorable: true,
  title: "Start here",
  isMandatory: false,
  activityProgress: {
    progressCount: 10,
    progressOf: 15,
  },
  progress: {
    inProgress: false,
    starsCount: 0,
    starsMax: 1,
    isCertified: false,
  },
  course: {
    isAttending: false,
  },
})
