import { useInfiniteGraphqlQuery } from "graphql-mock"
import { last } from "lodash-es"
import { ErrorPopup, WhenVisible } from "utility-components"
import { ProductCategoryProducts } from "../ProductCategoryProducts"
import { query, type Category } from "./gql"
import { ProductCategoriesLoading } from "./loading"
import s from "./styles.module.scss"

const ITEMS_PER_PAGE = 3

export const ProductCategories = () => {
  const { data, error, fetchNextPage, isFetchingNextPage } =
    useInfiniteGraphqlQuery(
      query,
      { limit: ITEMS_PER_PAGE },
      { getNextPage: (data) => data.productCategories.pageInfo.nextPage }
    )

  if (error) return <ErrorPopup error={error} />
  if (!data) return <ProductCategoriesLoading count={ITEMS_PER_PAGE} />

  const pageInfo = last(data.pages)?.productCategories.pageInfo
  if (!pageInfo) throw new Error("There must be at least one page")

  const categories = data.pages.flatMap((page) => page.productCategories.nodes)

  return (
    <>
      {categories.map((category) => (
        <ProductCategory data={category} key={category.id} />
      ))}

      {isFetchingNextPage && (
        <ProductCategoriesLoading count={pageInfo.nextPageItemCount} />
      )}

      <WhenVisible
        key={categories.length}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    </>
  )
}

const ProductCategory = ({ data }: { data: Category }) => {
  const { id, title } = data

  return (
    <div className={s.category}>
      <h2 className={s.category__title}>{title}</h2>
      <ProductCategoryProducts id={id} />
    </div>
  )
}
