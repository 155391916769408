import newsColorIcon from "assets/icons/newsColorIcon.svg"
import { useGraphqlQuery } from "graphql-mock"
import { Card } from "materia"
import type { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { sanitizeHtml } from "ui-tools"
import {
  ErrorPopup,
  FiestaImage,
  FiestaLink,
  type FiestaLinkProps,
} from "utility-components"
import { query } from "./gql"
import { NewsWidgetLoading } from "./loading"
import s from "./styles.module.scss"

export const NewsWidget = () => {
  const { data, error, isPending } = useGraphqlQuery(query, {})

  const { t } = useTranslation()

  if (isPending) return <NewsWidgetLoading />
  if (error) return <ErrorPopup error={error} />

  const newsPosts = data.newsPosts.nodes

  if (newsPosts.length === 0) {
    return (
      <NewsWidgetUI headerText={t("news.NEWS")}>
        <Empty emptyText={t("notFound.NOTFOUND_NEWS")} />
      </NewsWidgetUI>
    )
  }

  const items = newsPosts.map((item) => (
    <Item
      link={{ to: `/news/post/${item.id}` }}
      key={item.id}
      title={item.headline ?? ""}
      description={item.body ?? ""}
      image={item.image ?? ""}
    />
  ))

  return <NewsWidgetUI headerText={t("news.NEWS")}>{items}</NewsWidgetUI>
}

type NewsWidgetProps = {
  children: ReactNode
  headerText: string
}
const NewsWidgetUI = ({ children, headerText }: NewsWidgetProps) => (
  <Card borderRadius="24">
    <div className={s.widget}>
      <div className={s.widget__header}>
        <img className={s.widget__image} src={newsColorIcon} alt="" />
        <h2 className={s.widget__title}>{headerText}</h2>
      </div>
      <ul className={s.widget__items}>{children}</ul>
    </div>
  </Card>
)

type ItemProps = {
  link: FiestaLinkProps
  title: string
  description: string
  image: string
}
const Item = ({ link, title, image, description }: ItemProps) => (
  <li>
    <FiestaLink {...link} className={s.item} ariaLabel={title}>
      <h3 className={s.item__title}>{title}</h3>
      <FiestaImage className={s.item__image} src={image} alt="" sizes="60px" />
      <div
        className={s.item__description}
        dangerouslySetInnerHTML={sanitizeHtml(description, "strict")}
      />
    </FiestaLink>
  </li>
)

type EmptyProps = {
  emptyText: string
}
const Empty = ({ emptyText }: EmptyProps) => (
  <div className={s.empty}>{emptyText}</div>
)
