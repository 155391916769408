import dueDateClock from "assets/icons/dueDateClock.svg"
import dueDateOverdue from "assets/icons/dueDateOverdue.svg"
import lightningIcon from "assets/icons/lightningIcon.svg"
import starFilled from "assets/icons/starFilled.svg"
import { modifiers } from "ui-tools"
import s from "./styles.module.scss"

type BadgeType =
  | "bundle"
  | "default"
  | "dueDate"
  | "overdue"
  | "score"
  | "scoreNoPoints"
  | "webGames"

type BadgeSize = "small" | "medium"

export const Badge = ({
  title,
  size = "medium",
  type = "default",
}: {
  title: string
  size?: BadgeSize
  type?: BadgeType
}) => {
  return (
    <div className={modifiers(s, "badge", type, size)}>
      <BadgeIcon type={type} />
      <div className={s.badge__text}>{title}</div>
    </div>
  )
}

const BadgeIcon = ({ type }: { type: BadgeType }) => {
  const icon = getBadgeIcon(type)
  if (icon === undefined) return

  return <img className={s.badge__icon} src={icon} alt="" />
}

const getBadgeIcon = (type: BadgeType) => {
  switch (type) {
    case "bundle":
      return starFilled
    case "dueDate":
      return dueDateClock
    case "overdue":
      return dueDateOverdue
    case "webGames":
      return lightningIcon
  }
}
