import { type ResultOf } from "graphql-mock"
import { graphql, graphqlRequest } from "../../graphql"

type Data = ResultOf<typeof query>
type ProductProgress = Data["product"]["progress"]

export type ProgressChange =
  | { type: "high_score"; productId: string; highScore: number }
  | { type: "certified"; productId: string }

const query = graphql(`
  query CurrentProductProgress($productId: ID!) {
    product(id: $productId) {
      progress {
        isCertified
        highScore
        starsCount
      }
    }
  }
`)

const productProgressMap = new Map<string, ProductProgress>()

export const checkForProgressChange = async (
  productId: string
): Promise<ProgressChange | undefined> => {
  const { before, after } = await updateProductProgress(productId)

  if (before === undefined) return

  if (after.isCertified && !before.isCertified) {
    return { type: "certified", productId }
  }

  if (after.highScore > before.highScore) {
    return { type: "high_score", productId, highScore: after.highScore }
  }
}

const updateProductProgress = async (productId: string) => {
  const before = productProgressMap.get(productId)

  const result = await graphqlRequest({ query, variables: { productId } })

  const after = result.product.progress

  productProgressMap.set(productId, after)

  return { before, after }
}
