import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import playIcon from "assets/icons/playIcon.svg"
import starEmpty from "assets/icons/starEmpty.svg"
import starFilled from "assets/icons/starFilled.svg"
import { times } from "lodash-es"
import { ProgressBar } from "materia"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { FiestaImage, FiestaLink } from "utility-components"
import { Badge } from "../../../components/Badge"
import {
  getIsWebGamesProduct,
  getProductLink,
} from "../../../dataUtilities/productDataUtilities"
import type { Data } from "./gql"
import s from "./styles.module.scss"

export const JourneyCard = ({
  data,
  isLocked,
}: {
  data: Data
  isLocked: boolean
}) => {
  const { title, id, productType, progress, isScorable, isMandatory, course } =
    data

  const isFiveStarProduct = progress.starsMax === 5
  const isActivity = productType === "activity"

  return (
    <FiestaLink
      to={isLocked ? undefined : getProductLink(id, productType)}
      className={modifiers(s, "card", { isMandatory, isLocked, isActivity })}
      aria-haspopup="dialog"
    >
      {isFiveStarProduct && <Image data={data} />}
      <div className={s.bottom}>
        <div className={s.rowOne}>
          <h3 className={modifiers(s, "title", { isActivity })}>{title}</h3>
        </div>
        <div className={s.rowTwo}>
          <div className={s.rowTwo__wrapper}>
            {isScorable && <Stars data={data} />}

            {course?.isAttending ? (
              <Attending />
            ) : !isFiveStarProduct ? (
              <ProductType data={data} />
            ) : null}
          </div>
          {progress.isCertified && <Certified />}
        </div>
        {isActivity && <ActivityProgress data={data} />}
      </div>
      {isMandatory && <Mandatory />}
    </FiestaLink>
  )
}

const Certified = () => (
  <img className={s.certified} src={checkmarkCircleGreen} alt="Completed" />
)

const Image = ({ data }: { data: Data }) => {
  const { image } = data
  return (
    <div className={s.image}>
      <FiestaImage
        className={s.image__image}
        src={image}
        alt=""
        sizes="(width = small) 30px, 60px"
      />
      <img className={s.image__play} src={playIcon} alt="" />
    </div>
  )
}

const Stars = ({ data }: { data: Data }) => {
  const { progress } = data

  const { starsMax, starsCount } = progress

  const getSrc = (isFilled: boolean) => (isFilled ? starFilled : starEmpty)

  const starElements = times(starsMax, (index) => (
    <img src={getSrc(index < starsCount)} alt="" key={index} />
  ))

  return (
    <div className={s.stars} aria-label={`${starsCount} of ${starsMax} stars`}>
      {starElements}
    </div>
  )
}

const ProductType = ({ data }: { data: Data }) => {
  const { t } = useTranslation()

  const { productType, progress } = data
  const isWebGamesProduct = getIsWebGamesProduct(productType)
  const inProgress = progress.inProgress && productType === "xapi"
  const productTypeText = t(`product.PRODUCT_TYPES.${productType}`)

  if (isWebGamesProduct) {
    return <Badge title={productTypeText} type="webGames" size="small" />
  } else {
    return (
      <div className={s.productType}>
        {productTypeText}{" "}
        {inProgress && ` (${t("product.PRODUCT_IN_PROGRESS")})`}
      </div>
    )
  }
}

const Attending = () => {
  const { t } = useTranslation()
  return <div className={s.attending}>{t("event.ATTENDING")}</div>
}

const ActivityProgress = ({ data }: { data: Data }) => {
  const { activityProgress, progress, productType } = data
  const isActivity = productType === "activity"

  return (
    <div className={modifiers(s, "progress", { isActivity })}>
      <ProgressBar
        value={activityProgress?.progressCount ?? 0}
        maxValue={activityProgress?.progressOf ?? 100}
        color={progress.isCertified ? "green-30" : "blue-40"}
      />
    </div>
  )
}

const Mandatory = () => {
  const { t } = useTranslation()
  return (
    <div className={s.mandatory}>
      <Badge title={t("user.MANDATORY")} size="small" />
    </div>
  )
}
