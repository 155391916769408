import arrow from "assets/icons/arrowRight.svg"
import { useGraphqlQuery } from "graphql-mock"
import { useTranslation } from "react-i18next"
import { sanitizeHtml } from "ui-tools"
import {
  ErrorPopup,
  FiestaImage,
  FiestaLink,
  type FiestaLinkProps,
} from "utility-components"
import { query } from "./gql"
import { NewsWidgetMobileLoading } from "./loading"
import s from "./styles.module.scss"

export const NewsWidgetMobile = () => {
  const { t } = useTranslation()
  const { data, error, isPending } = useGraphqlQuery(query, {})

  if (isPending) return <NewsWidgetMobileLoading />
  if (error) return <ErrorPopup error={error} />

  const isEnabled =
    data.currentCompany.portalConfigurations.includes("enable_portal_news")

  if (!isEnabled) return null

  const newsPost = data.newsPosts.nodes[0]

  return (
    <div className={s.newsWidget}>
      <div className={s.widget}>
        {newsPost ? (
          <Item
            link={{ to: `/news/post/${newsPost.id}` }}
            key={newsPost.id}
            title={t("news.NEWS")}
            description={newsPost.headline ?? ""}
            image={newsPost.image ?? ""}
            arrow={arrow}
          />
        ) : (
          <Empty emptyText={t("notFound.NOTFOUND_NEWS")} />
        )}
      </div>
    </div>
  )
}

const Item = ({
  link,
  title,
  description,
  image,
  arrow,
}: {
  link: FiestaLinkProps
  title: string
  description: string
  image: string
  arrow: string
}) => {
  return (
    <FiestaLink {...link} className={s.item}>
      <FiestaImage className={s.item__image} src={image} alt="" sizes="60px" />
      <div className={s.item__wrapper}>
        <div className={s.item__title}>{title}</div>
        <h3
          className={s.item__description}
          dangerouslySetInnerHTML={sanitizeHtml(description, "strict")}
        />
      </div>
      <div className={s.item__arrowContainer}>
        <FiestaImage
          className={s.item__arrow}
          src={arrow}
          alt=""
          sizes="20px"
        />
      </div>
    </FiestaLink>
  )
}

const Empty = ({ emptyText }: { emptyText: string }) => (
  <div className={s.empty}>{emptyText}</div>
)
