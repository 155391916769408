import productImage from "assets/demo/productImage.svg"
import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { times } from "lodash-es"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>
export type ProductCategory = Data["productCategories"]["nodes"][number]
export type Product = NonNullable<ProductCategory["products"]["nodes"][number]>

export const query = graphql(`
  query FeaturedProducts {
    productCategories(isFeatured: true, isJourney: false, limit: 1) {
      nodes {
        products {
          nodes {
            id
            title
            image
            productType
            isMandatory
            progress {
              starsCount
              starsMax
              highScore
              isCertified
            }
            deadlineOccurrence {
              status
            }
          }
        }
      }
    }
  }
`)

export const mockFeaturedProductsData = mockData<Data>({
  productCategories: {
    nodes: [
      {
        products: {
          nodes: times(3, (index) => ({
            id: index.toString(),
            image: productImage,
            productType: "pdf",
            title: `Product #${index}`,
            isMandatory: false,
            progress: {
              isCertified: true,
              highScore: 200000,
              starsCount: 5,
              starsMax: 5,
            },
            deadlineOccurrence: {
              status: "certified",
            },
          })),
        },
      },
    ],
  },
})

registerMockData(query, mockFeaturedProductsData())
