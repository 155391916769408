import productImage from "assets/demo/productImage.svg"
import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query ProductMiniModal($productId: ID!) {
    product(id: $productId) {
      id
      title
      image
      progress {
        starsCount
        starsMax
      }
    }
  }
`)

export const mockProductMiniModalData = mockData<Data>({
  product: {
    id: "1",
    title: "Salestraining",
    image: productImage,
    progress: {
      starsCount: 5,
      starsMax: 5,
    },
  },
})

registerMockData(query, mockProductMiniModalData())
