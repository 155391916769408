import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import chevronIcon from "assets/icons/chevronIcon.svg"
import { useTranslation } from "react-i18next"
import { FiestaLink } from "utility-components"
import { Badge } from "../Badge"
import { ProductCardShell } from "../ProductCard/shell"
import type { Data } from "./gql"
import s from "./styles.module.scss"

export const ProductBundleCard = ({ data }: { data: Data }) => {
  const { id, products, progress, title } = data
  const { t } = useTranslation()

  const countText = t("bundle.products", {
    count: products.pageInfo.totalItemCount,
  })

  const images = products.nodes.map((product) => product.image)

  return (
    <li>
      <ProductCardShell>
        <FiestaLink to={`/bundle/${id}`} className={s.bundle}>
          {progress.isCertified && <Certified />}
          <div className={s.bundle__images}>
            {images.map((image, index) => (
              <img key={index} className={s.bundle__image} src={image} alt="" />
            ))}
          </div>
          <div className={s.bundle__container}>
            <div className={s.bundle__count}>{countText}</div>
            <div className={s.bundle__title}>{title}</div>
            <div className={s.bundle__bottom}>
              <Badge
                title={`${progress.starsCount}/${progress.starsMax}`}
                type="bundle"
              />
              <img className={s.bundle__chevron} src={chevronIcon} alt="" />
            </div>
          </div>
        </FiestaLink>
      </ProductCardShell>
    </li>
  )
}

const Certified = () => (
  <img className={s.certified} src={checkmarkCircleGreen} alt="Completed" />
)
